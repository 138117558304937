import React, { Component } from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import { IntercomAPI } from 'react-intercom';
import './css/call-to-action.scss';

import Button from './Button';

@inject('Store')
@observer
export default class CallToAction extends Component {
  static defaultProps = {
    size: 'lg',
    location: 'global'
  };

  handlePhoneCall = () => {
    window.open('tel:+18332724429');
  };

  openChat = () => {
    IntercomAPI('show');
  };

  openEmail = () => {
    const { Store } = this.props;
    Store.App.email.toggleModal();
  };

  render() {
    const { location, size } = this.props;
    const fullLabel = ['footer', 'simple'].includes(location);

    const phoneLabel = fullLabel ? '833.272.4429' : 'Call';
    const chatLabel = fullLabel ? 'Live Chat' : 'Chat';
    const emailLabel = fullLabel ? 'sales@braggawatt.com' : 'Email';
    const btnType = fullLabel ? 'simple' : 'secondary';

    return (
      <div
        className={cx(`a-callToAction -${location}`, {
          '-inline': location === 'footer'
        })}>
        <Button
          size={size}
          type={btnType}
          icon="phone"
          label={phoneLabel}
          onClick={this.handlePhoneCall}
        />
        <Button
          size={size}
          type={btnType}
          icon="chat"
          label={chatLabel}
          onClick={this.openChat}
        />
        <Button
          size={size}
          type={btnType}
          icon="email"
          label={emailLabel}
          onClick={this.openEmail}
        />
      </div>
    );
  }
}
