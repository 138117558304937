import React from 'react';
import Head2 from '@bw/library/headings/Head2';
import roofSolarPanels from '../images/sjfmc/roof-solar-panels.jpg';
import roofSolarPanels2x from '../images/sjfmc/roof-solar-panels@2x.jpg';

import roofSolarPanelsSide from '../images/sjfmc/roof-solar-panels-side.jpg';
import roofSolarPanelsSide2x from '../images/sjfmc/roof-solar-panels-side@2x.jpg';

import solarInverters from '../images/sjfmc/solar-inverters.jpg';
import solarInverters2x from '../images/sjfmc/solar-inverters@2x.jpg';

import guysOnSolarRooftop from '../images/sjfmc/guys-solar-rooftop-mobile-tablet-1000px.jpg';

import wholeSolarPanels from '../images/sjfmc/sjfmc-full-solar-panels-mobile-1000px.jpg';
import wholeSolarPanels2x from '../images/sjfmc/sjfmc-full-solar-panels-desktop-1500px.jpg';
import './css/gallery.scss';
export default () => {
  return (
    <div className="m-gallery">
      <Head2>Project Photo Gallery</Head2>
      <div className="m-gallery__group">
        <div
          className="m-gallery__image -one"
          data-aos="zoom-out"
          data-aos-delay="100">
          <picture>
            <source media="(max-width: 576px)" srcSet={roofSolarPanels} />
            <img src={roofSolarPanels2x} alt="Roof solar panels" />
          </picture>
        </div>

        <div
          className="m-gallery__image -two"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <source media="(max-width: 576px)" srcSet={roofSolarPanelsSide} />
            <img src={roofSolarPanelsSide2x} alt="Roof solar panels side" />
          </picture>
        </div>

        <div
          className="m-gallery__image -three"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <source media="(max-width: 576px)" srcSet={solarInverters} />
            <img src={solarInverters2x} alt="Solar Inverters" />
          </picture>
        </div>

        <div
          className="m-gallery__image -four"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <img src={guysOnSolarRooftop} alt="Guys on solar panel roof" />
          </picture>
        </div>

        <div
          className="m-gallery__image -five"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <source media="(max-width: 576px)" srcSet={wholeSolarPanels} />
            <img src={wholeSolarPanels2x} alt="Whole solar panels" />
          </picture>
        </div>
      </div>
    </div>
  );
};
