import styled from 'styled-components';

export default styled.div`
  .title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 12px;
    text-align: center;
    margin-top: 40px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme['res-md']}) {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 768px;
      margin: 0 auto;
    }

    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: nowrap;
      max-width: 1070px;
      margin: 24px auto 52px;
    }
  }
`;
