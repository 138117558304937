import { apiPromise } from '@bw/services/apiRequest';
/**
 * Post a slack message to a given channel.
 * @param {String} message
 * @param {String} channel
 */
export function postSlack(message, channel) {
  const request = {
    method: 'POST',
    url: '/slack_message',
    body: {
      message,
      channel
    }
  };
  return apiPromise(request);
}
