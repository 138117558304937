import { apiPromise } from '@bw/services/apiRequest';
/**
 * @param {Object} data
 * @returns {Promise}
 */
export function submitIntegrator(data) {
  const request = {
    data,
    method: 'POST',
    url: '/signup_integrator'
  };

  return apiPromise(request);
}
