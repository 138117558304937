import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IntercomAPI } from 'react-intercom';

import Dropdown from '../dropdown/Dropdown';
import DropdownTrigger from '../dropdown/DropdownTrigger';
import DropdownContent from '../dropdown/DropdownContent';
import WhySolarDropdown from './WhySolarDropdown';
import WhyBwDropdown from './WhyBwDropdown';
import BWLink from './BWLink';

import './desktop-about.scss';

@inject('Store')
@observer
export default class DesktopAbout extends Component {
  constructor(props) {
    super(props);
    this.whySolarRef = React.createRef();
    this.whatWeDoRef = React.createRef();
  }

  state = {
    whySolarDropdown: false,
    whatWeDoDropdown: false
  };

  handlePhoneCall = () => {
    window.open('tel:+18332724429');
  };

  openChat = () => {
    IntercomAPI('show');
  };

  openEmail = () => {
    const {
      Store: { App }
    } = this.props;
    App.email.toggleModal();
  };

  toggleDropdown = dropdown => {
    const defaultState = {
      whySolarDropdown: false,
      whatWeDoDropdown: false
    };

    this.setState(previous => {
      return {
        ...defaultState,
        [dropdown]: !previous[dropdown]
      };
    });
  };

  render() {
    const { whySolarDropdown, whatWeDoDropdown } = this.state;
    const { directLink } = this.props;
    return (
      <div className="m-desktopAbout">
        <Dropdown
          className="m-desktopAbout__whySolar"
          minimal={true}
          navigation={true}
          active={whySolarDropdown}
          onClick={() => this.toggleDropdown('whySolarDropdown')}
          ref={this.whySolarRef}>
          <DropdownTrigger>Why Solar?</DropdownTrigger>
          <DropdownContent>
            <WhySolarDropdown directLink={directLink} />
          </DropdownContent>
        </Dropdown>

        <BWLink to="/solar-for-business" directLink={directLink}>
          For Business
        </BWLink>

        <BWLink to="/solar-for-nonprofit" directLink={directLink}>
          For Nonprofit
        </BWLink>

        <Dropdown
          minimal={true}
          navigation={true}
          active={whatWeDoDropdown}
          onClick={() => this.toggleDropdown('whatWeDoDropdown')}
          ref={this.whatWeDoRef}>
          <DropdownTrigger>Our Process</DropdownTrigger>
          <DropdownContent>
            <WhyBwDropdown directLink={directLink} />
          </DropdownContent>
        </Dropdown>

        <BWLink
          to="/solar-integrator-financing-platform"
          directLink={directLink}>
          Solar Installers
        </BWLink>
      </div>
    );
  }
}
