import React, { PureComponent } from 'react';
import cx from 'classnames';
import './css/icon.scss';

export default class Icon extends PureComponent {
  handleOnClick = event => {
    const { onClick, disabled } = this.props;
    if (disabled) return null;
    if (onClick) onClick(event);
  };

  render() {
    const {
      id,
      disabled,
      className,
      icon,
      cursorPointer,
      onClick,
      tooltip,
      size,
      cy
    } = this.props;
    const classNames = cx(`bw-icon--${icon} a-icon`, {
      [className]: className,
      [`-size${size}`]: size,
      '-cursorPointer': cursorPointer || onClick,
      '-hasTooltip': tooltip,
      disabled
    });
    return (
      <i
        data-cy={cy}
        id={id}
        onClick={this.handleOnClick}
        className={classNames}
        data-tooltip={tooltip}
      />
    );
  }
}
