import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';
import Intercom from 'react-intercom';

import NavBar from '@bw/library/topNavigation/NavBar';
import PrivacyPolicyModal from '@bw/library/modal/PrivacyPolicyModal';
import ModalV2 from '@bw/library/modal/ModalV2';
import EmailSupportModal from '@bw/library/EmailSupportModal';
import { withAlert } from '@bw/library/alert';
import ZipCodeEntry from '@bw/modules/Marketing/components/ZipCodeEntry';
import { intercom } from '@bw/config';

@inject('Store')
@withAlert
@observer
export default class Layout extends Component {
  state = {
    visible: false
  };

  componentDidMount() {
    const { alert, Store } = this.props;
    Store.App.initAlert(alert);
    this.setState({ visible: true });
  }

  render() {
    const { visible } = this.state;
    const { Store, children } = this.props;
    const settings = {
      horizontal_padding: 28,
      vertical_padding: 28,
      hide_default_launcher: false
    };

    return (
      <Fragment>
        <NavBar />
        <div id="MAIN">
          {process.env.NODE_ENV === 'production' && (
            <Intercom appID={intercom.app_id} {...settings} />
          )}

          <EmailSupportModal />

          <ModalV2
            open={Store.App.ui.zipEntry}
            onClose={Store.App.ui.closeZipEntry}
            center={true}>
            <ZipCodeEntry
              minimal={true}
              modal={true}
              btnId="BTN_ZIPCODE_ENTRY_MODAL"
              useLocId="BTN_USE_LOCATION_MODAL"
            />
          </ModalV2>

          <PrivacyPolicyModal />

          {children}
        </div>
      </Fragment>
    );
  }
}
