import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { observer } from 'mobx-react';

import InputErrorField from './InputErrorField';
import './css/input-textarea.scss';

@observer
export default class InputTextArea extends Component {
  static propTypes = {
    className: PropTypes.string,
    formField: PropTypes.object,
    id: PropTypes.string,
    inputClassName: PropTypes.string,
    inputStyle: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    showLabel: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string
  };

  static defaultProps = {
    type: 'text',
    showLabel: true
  };

  render() {
    const {
      className,
      disabled,
      formField,
      id,
      inputClassName,
      inputStyle,
      label,
      name,
      onKeyUp,
      placeholder,
      showLabel,
      type
    } = this.props;

    const containerClassNames = cx('a-inputTextArea', {
      [className]: className,
      'a-inputTextArea-error': formField.error
    });

    const inputClassNames = cx({
      [inputClassName]: inputClassName
    });

    const displayedLabel = label || formField.label;

    return (
      <div className={containerClassNames}>
        <label htmlFor={formField.id}>
          {displayedLabel && showLabel && displayedLabel}
        </label>
        <div className="input-container">
          <textarea
            {...formField.bind()}
            onKeyUp={onKeyUp}
            className={inputClassNames}
            name={name}
            id={id}
            min={0}
            type={type}
            style={inputStyle}
            placeholder={placeholder}
            disabled={disabled}
          />
          <InputErrorField text={formField.error} />
        </div>
      </div>
    );
  }
}
