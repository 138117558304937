export default {
  'color-info': '#2a7cbe',
  /* Gray scale */
  'color-gray5': '#33353e',
  'color-gray4': '#5e616b',
  'color-gray3': '#b1b6c9',
  'color-gray2': '#e4e5ec',
  'color-gray1': '#f6f7fe',
  'color-white': 'rgba(255, 255, 255, 1)',

  /* Primary Color */
  'color-primary': '#ffc107',
  'color-primary-text': 'rgba(255, 255, 255, 1)',
  'color-primary-hover': '#ffc30d',
  'color-primary-active': '#f4bb11',

  /* Secondary color */
  'color-secondary': 'rgba(255, 255, 255, 1)',
  'color-secondary-text': '#33353e',
  'color-secondary-border': '#e4e5ec',
  'color-secondary-hover': '#f6f7fe',
  'color-secondary-hover-border': '#e4e5ec',
  'color-secondary-active': '#e4e5ec',

  /* Destructive Color */
  'color-destructive': '#c71b27',
  'color-destructive-text': 'rgba(255, 255, 255, 1)',
  'color-destructive-hover': '#d51f2c',
  'color-destructive-active': '#b51823',

  /* Success Color */
  'color-success': '#a7d500',
  'color-success-text': 'rgba(255, 255, 255, 1)',
  'color-success-hover': '#b0e000',
  'color-success-active': '#9ec901',

  /* Disabled State */
  'color-disabled': '#f6f7fe',
  'color-disabled-text': '#b1b6c9',
  'color-disabled-border': '#e4e5ec',

  /* Error State */
  'color-warning': 'rgba(255, 255, 255, 1)',
  'color-warning-text': '#c71b27',
  'color-warning-border': '#c71b27',

  'res-sm': '576px',
  // Medium devices (tablets, 768px and up)
  'res-md': '768px',
  // Large devices (desktops, 992px and up)
  'res-lg': '992px',
  // Extra large devices (large desktops, 1200px and up)
  'res-xl': '1200px',
  'res-xxl': '1517px',

  'max-width': '1440px',
  'input-max-width': '250px',
  'input-font-size': '18px',
  'input-border-color': '#e4e5ec',
  'input-border-color-hover': '#b1b6c9',
  'box-shadow': '0 4px 23px 0 rgba(222, 236, 248, 0.81)',
  'box-shadow2': '0 10px 30px 0 rgba(222, 236, 248, 0.81)',
  'box-shadow-blur': '0 4px 23px 0 rgba(222, 236, 248, 0)',
  'text-shadow': '0 5px 2px #000000'
};
