import React from 'react';
import cx from 'classnames';

import './css/button-action-group.scss';

export default ({ align, children, className, noMargin, inline, size }) => {
  const btnCx = cx('a-buttonActionGroup', {
    [className]: className,
    '-noMargin': noMargin,
    '-inline': inline,
    [`-${align}`]: align,
    [`-${size}`]: size,
    '-mobileCenter': React.Children.count(children) > 2
  });

  return <div className={btnCx}>{children}</div>;
};
