import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DropdownTrigger extends Component {
  static displayName = 'DropdownTrigger';

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, className, ...dropdownTriggerProps } = this.props;
    dropdownTriggerProps.className = `a-dropdown__trigger ${className}`;

    return <a {...dropdownTriggerProps}>{children}</a>;
  }
}
