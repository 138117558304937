import styled from 'styled-components';

export default styled.input`
  -webkit-appearance: none;
  border-radius: 3px;
  border: 1px solid ${props => props.theme['input-border-color']};
  box-shadow: ${props => props.theme['box-shadow-blur']};
  box-sizing: border-box;
  color: ${props => props.theme['color-gray4']};
  font-size: ${props => props.theme['input-font-size']};
  height: 50px;
  outline: none;
  padding: 0 20px;
  text-align: left;
  transition: 0.2s;
  width: 100%;
  transition: 0.2s;

  &:hover,
  &:focus {
    border-color: ${props => props.theme['input-border-color-hover']};
    box-shadow: ${props => props.theme['box-shadow']};
  }

  &:read-only,
  &:disabled {
    background: ${props => props.theme['color-disabled']};
    border-color: ${props => props.theme['color-disabled-border']};
    color: ${props => props.theme['color-disabled-text']};
    cursor: not-allowed;
    box-shadow: 0 0 0 0;
    &:hover {
      border-color: ${props => props.theme['color-gray2']} !important;
      box-shadow: 0 0 0 0;
    }
  }

  &.-hasPrefix {
    padding-left: 35px;
  }

  &.-center {
    text-align: center;
  }
`;
