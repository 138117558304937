export default [
  {
    name: 'email',
    fields: [
      { name: 'name', label: 'Full name', rules: 'required' },
      { name: 'address', label: 'Email Address', rules: 'required|email' },
      { name: 'message', label: 'Message', rules: 'required' },
      { name: 'reason', label: 'Reason', rules: 'required|requiredSelect' }
    ]
  }
];
