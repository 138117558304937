import React from 'react';
import { Link } from 'react-router-dom';
import Row from '@bw/library/Row';
import Column from '@bw/library/Column';
import Head2 from '@bw/library/headings/Head2';
import StyledWhySolarInfo from './StyledWhySolarInfo';
import Description from './Description';
import goodForCommunityIcon from '../../images/community-icon.svg';
import cashFlowIcon from '../../images/cashflow-icon.svg';
import solarBusinessIcon from '../../images/solar-business-icon.svg';

const goodForCommunity = [
  {
    id: 'CLEAN_GREEN',
    label: 'Clean & Green',
    desc:
      'Solar energy systems are fueled by the sun, have no moving parts, create no noise, and produce no emissions or harmful byproducts – only clean electricity.'
  },
  {
    id: 'REDUCE_EMISSIONS',
    label: 'Reduce emissions and improve local air quality',
    desc:
      'Unlike our conventional energy generation systems - which are a key source of pollution, chronic health conditions, and global warming – your onsite solar energy system will reduce greenhouse gas emissions and improve air quality in your local community.'
  }
];
const betterPrice = [
  {
    id: 'REDUCE_ELECTRICITY',
    label: 'Reduce your Electricity Costs',
    desc: (
      <p>
        <Link to="/solar-financial-benefits">
          Decrease your monthly utility costs
        </Link>{' '}
        by generating solar electricity for less than it costs to buy it from
        your local utility provider. Even though you won’t eliminate your entire
        utility bill, you will see savings.
      </p>
    )
  },
  {
    id: 'PROTECT_AGAINST_RISING_ENERGY',
    label: 'Protect Against Rising Energy Prices',
    desc: (
      <p>
        When you <Link to="/how-solar-energy-works">generate your own</Link>{' '}
        electricity, you are protected from rising energy costs and inflation
        since the price of solar energy is predetermined for the life of the
        system.
      </p>
    )
  },
  {
    id: 'BENEFITS_SOLAR_INCENTIVES',
    label: 'Benefit from Solar Tax and Grant Incentives',
    desc: (
      <p>
        The most significant incentive is the 30 percent federal{' '}
        <a
          href="https://www.seia.org/initiatives/solar-investment-tax-credit-itc"
          target="_blank">
          Investment Tax Credit (ITC)
        </a>
        , which is a dollar-for-dollar reduction in income taxes. Furthermore,
        many jurisdictions offer additional solar incentives, including cash
        grants and performance-based Renewable Energy Credits (RECs).
      </p>
    )
  },
  {
    id: 'REDUCE_TAXES',
    label: 'Reduce your Taxes',
    desc: (
      <p>
        For-profit companies can benefit from another significant income tax
        deduction that allows them to{' '}
        <a
          href="https://www.seia.org/initiatives/depreciation-solar-energy-property-macrs"
          target="_blank">
          depreciate the capital investment in their solar system
        </a>{' '}
        on an accelerated schedule. This helps our customers invest in
        themselves, rather than their utility.
      </p>
    )
  },
  {
    id: 'SELL_EXCESS',
    label: 'Sell Excess Solar Energy',
    desc: (
      <p>
        Most utility companies provide a credit on your bill for the excess
        energy your system generates that your building doesn’t need (for
        example, solar generated on weekends). This is called{' '}
        <Link to="/solar-financial-benefits#NET_METERING">Net Metering.</Link>
      </p>
    )
  }
];

const brandElevation = [
  {
    id: 'BUILD_TRUST',
    label: 'Build your Brand',
    desc:
      'Customers see renewable solar energy as a key differentiator when they choose to do business with sustainable companies.'
  },
  {
    id: 'LOCAL_EMP',
    label: 'Invest in your Employees',
    desc:
      'Attract and retain talent who value your commitment to sustainability and seek to work in an innovative environment.'
  },
  {
    id: 'INVEST_ECON',
    label: 'Invest in your Local Economy',
    desc:
      'Installing and maintaining solar systems creates high-quality jobs that help your local community thrive.'
  }
];

export default function WhySolarInfo() {
  return (
    <StyledWhySolarInfo>
      <Row>
        <Column className="infoCol" sizeLg={4}>
          <div className="infoHead">
            <img src={goodForCommunityIcon} alt="img" />
            <Head2>Good for your Community</Head2>
          </div>

          {goodForCommunity.map(item => {
            return (
              <Description
                id={item.id}
                key={item.id}
                header={item.label}
                text={item.desc}
              />
            );
          })}
        </Column>
        <Column className="infoCol" sizeLg={4}>
          <div className="infoHead">
            <img src={cashFlowIcon} alt="img" />
            <Head2>Increases Your Cashflow</Head2>
          </div>
          {betterPrice.map(item => {
            return (
              <Description
                id={item.id}
                key={item.id}
                header={item.label}
                text={item.desc}
              />
            );
          })}
        </Column>
        <Column className="infoCol" sizeLg={4}>
          <div className="infoHead">
            <img src={solarBusinessIcon} alt="img" />
            <Head2>Best for Your Business</Head2>
          </div>
          {brandElevation.map(item => {
            return (
              <Description
                id={item.id}
                key={item.id}
                header={item.label}
                text={item.desc}
              />
            );
          })}
        </Column>
      </Row>
    </StyledWhySolarInfo>
  );
}
