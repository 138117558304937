import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactSVG from 'react-svg';

import Head2 from '@bw/library/headings/Head2';
import Head3 from '@bw/library/headings/Head3';
import Lazy from '@bw/library/Lazy';
import WithFooter from '@bw/library/footer/WithFooter';
import ReadMore from '@bw/library/ReadMore';
import VideoWrapper from '@bw/library/containers/VideoWrapper';
import caseStudyImg from '@bw/modules/CaseStudies/images/sjfmc/sjfmc-full-solar-panels-mobile-1000px.jpg';
import ButtonLinkV2 from '@bw/library/buttons/ButtonLinkV2';

import QuoteSlider from './carousel';

import ZipCodeWrapper from './homepage/ZipCodeWrapper';
import WhySolarInfo from './solar/WhySolarInfo';
import CTALink from './CTALink';
import ZipCodeEntry from './ZipCodeEntry';
import LearnMore from './LearnMore';
import Hero from './homepage/HeroStyled';
import FeaturedIn from './about/FeaturedIn';
import videoThumbnail from '../images/video-1-thumbnail@2x.jpg';
import checklistIcon from '../images/white-checklist-icon.svg';
import cashIcon from '../images/cash-icon.svg';
import solarIcon from '../images/solar-icon.svg';
import arrowIcon from '../images/yellow-icon.svg';
import metaCard from '../../CaseStudies/images/wildwood/building-desktop.jpg';
import * as C from '../constants';
import './css/landing-page.scss';

export default class LandingPage extends Component {
  render() {
    return (
      <WithFooter className="o-landingPage" background={true}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Solar for business is more affordable than you think. Braggawatt offers easy solar financing for businesses of all types and locations. Nonprofit? Contact us for solar financing solutions today!"
          />
          <meta
            name="og:description"
            content="Solar for business is more affordable than you think. Braggawatt offers easy solar financing for businesses of all types and locations. Nonprofit? Contact us for solar financing solutions today!"
          />
          <meta
            property="og:title"
            content="Solar for Business: Solar Financing for Business & Nonprofits"
          />
          <meta property="og:image" content={metaCard} />
          <meta property="og:url" content="https://www.braggawatt.com" />
          <meta name="twitter:card" content="summary_large_image" />
          <title>
            Solar for Business: Solar Financing for Business & Nonprofits
          </title>
        </Helmet>
        <Hero>
          <div className="header">
            <h1 align="center">
              Simplifying solar savings for business and nonprofits.
            </h1>
            <div className="sub">
              The all-in-one solution for your commercial solar project.
            </div>
          </div>
          <div className="offers">
            <div className="offers__item">
              <ReactSVG src={checklistIcon} />
              Assessment
            </div>
            <ReactSVG className="offers__arrow" src={arrowIcon} />
            <div className="offers__item">
              <ReactSVG src={cashIcon} />
              Financing
            </div>
            <ReactSVG className="offers__arrow" src={arrowIcon} />
            <div className="offers__item">
              <ReactSVG src={solarIcon} />
              Installation
            </div>
          </div>
          <div className="cta">
            <ButtonLinkV2 to="/solar-for-business">Business</ButtonLinkV2>
            <ButtonLinkV2 to="/solar-for-nonprofit">Nonprofit</ButtonLinkV2>
          </div>
        </Hero>
        <div className="intro">
          <Head2>The problem with solar</Head2>
          <p>
            As a key leader of a small business or nonprofit, you believe in the
            benefits of solar energy. Solar cuts utility costs. Solar provides
            jobs for the local economy. Solar is good for our planet. Solar
            makes you stand out. In theory, installing a commercial solar panel
            system makes sense.
          </p>
          <p>
            But you’ve also heard it’s more complicated than that. You think
            solar for business is unreliable or that the process of installing a
            solar energy system is so difficult it’s not worth your time and
            effort.
          </p>

          <Head2>Braggawatt is changing all this.</Head2>

          <p>
            Our satisfied customers install completely financed solar energy
            systems for $0 down and see savings day one. Don’t believe us?
            Here’s a video on how it works:
          </p>
        </div>
        <ZipCodeWrapper>
          <div className="video">
            <VideoWrapper
              assetId="VIDEO_HOMEPAGE"
              url="https://www.youtube.com/embed/z_gINeK_OBQ"
              thumbnail={videoThumbnail}
            />
          </div>
          <div className="form">
            <ZipCodeEntry />
            <Link to="/braggawatt-solar-process">
              How does the calculator work?
            </Link>
          </div>
        </ZipCodeWrapper>
        <div style={{ marginTop: '75px' }} />
        <div id="quoteSlider">
          <Head2 data-aos="fade-up" align="center">
            Our Partners Love Us
          </Head2>
          <div id="quoteSliderWrapper">
            <QuoteSlider />
          </div>
        </div>
        <FeaturedIn title={true} />
        <div className="caseStudyWrapper">
          <div className="caseStudy">
            <Head2
              align="left"
              className="caseStudy__header"
              data-aos="fade-up">
              How a nonprofit solar energy system cut this hospital’s electrical
              bill by 37%
            </Head2>
            <div className="caseStudy__imageBox -caseStudyImg">
              <Lazy src={caseStudyImg} />
            </div>
            <div
              className="caseStudy__desc -caseStudyDescription"
              data-aos="fade-up"
              data-aos-delay="200">
              <p>
                After struggling to go solar for over two years, Ronald Kaplan,
                CFO of Southern Jersey Family Medical Center, was finally able
                to find “commercial solar financing terms that no other provider
                was able to offer” through Braggawatt’s easy-to-use platform.
                SJFMC started saving on their electricity costs from day one.
              </p>
              <CTALink
                label="View the Case Study"
                link="/case-studies/southern-jersey-family-medical-center"
              />
            </div>
          </div>
        </div>

        <div className="solarBenefits">
          <Head2 align="center">
            Benefits of Solar for Businesses and Nonprofits
          </Head2>
          <WhySolarInfo />
        </div>

        <LearnMore>
          <Head2 data-aos="fade-up">
            Learn More About the Benefits of Solar
          </Head2>
          <ReadMore
            items={[
              C.readMoreSolarWork(),
              C.READ_MORE_FINANCIAL_BENEFITS,
              C.READ_MORE_SOLAR_PANELS
            ]}
          />
        </LearnMore>

        <ZipCodeEntry
          heading="How much could you be saving?"
          subheading="Calculate now"
          minimal={true}
          btnId="BTN_ZIPCODE_ENTRY_MIN"
          useLocId="BTN_USE_LOCATION_MIN"
        />
      </WithFooter>
    );
  }
}
