import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './hamburger-menu.scss';

@inject('Store')
@observer
export default class HamburgerMenu extends Component {
  render() {
    const {
      Store: { App },
      isAuthenticated
    } = this.props;
    return (
      <div className="a-hamburgerMenu" onClick={App.ui.toggleMobileMenu}>
        {!isAuthenticated && 'Menu'} <span />
      </div>
    );
  }
}
