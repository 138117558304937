import styled from 'styled-components';

export default styled.div`
  color: ${({ theme }) => theme['color-white']};
  width: 100%;
  max-width: 281px;
  text-shadow: ${({ theme }) => theme['text-shadow']};
  font-weight: 900;
  font-size: 32px;
  margin-top: -272px;
  padding: 0 15px;
  position: relative;

  @media (min-width: ${({ theme }) => theme['res-md']}) {
    height: auto;
    max-width: 920px;
    margin: -188px auto 0px;
  }

  @media (min-width: ${({ theme }) => theme['res-lg']}) {
    font-size: 38px;
    margin: -246px auto 0px;
    max-width: 960px;
    line-height: 1.4;
  }

  .inner {
    max-width: 593px;
  }
`;
