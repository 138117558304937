export const DATE_FORMAT = 'MM/DD/YYYY';

export const VIDEO_PLAYBACK_STARTED = 'Video Playback Started';
export const VIDEO_PLAYBACK_PAUSED = 'Video Playback Paused';
export const VIDEO_PLAYBACK_COMPLETED = 'Video Playback Completed';
export const VIDEO_PLAYBACK_SEEK = 'Video Playback Seek Completed';
export const VIDEO_PLAYBACK_SEEK_STARTED = 'Video Playback Seek Started';
export const VIDEO_PLAYBACK_INTERRUPTED = 'Video Playback Interrupted';
export const ACCOUNT_CREATED = 'Account Created';
export const SIGNED_UP = 'Signed Up';

export const CTA_ZIPCODE = 'CTA Zipcode Entry';
export const CTA_AVG_BILL = 'CTA Average Monthly Bill';
export const CTA_NON_PROFIT = 'CTA Non Profit';
export const CTA_EMAIL_SME = 'CTA Email Address SME';
