import React, { Component } from 'react';
import Lazy from '@bw/library/Lazy';
import Head3 from '@bw/library/headings/Head3';
import cx from 'classnames';
import sntImg from '../../images/about/solar-novus-today-logo.svg';
import yahooImg from '../../images/about/yahoo-finance-logo.svg';
import smartGridImg from '../../images/about/smart-grid-logo.svg';
import solarBuilderImg from '../../images/about/solar-builder-logo.svg';
import solarPowerWorldImg from '../../images/about/solar-power-world-logo.svg';
import cioImg from '../../images/about/energy-cio-insights-top-10-energy-logo.svg';
import businessWireImg from '../../images/about/business-wire-logo.svg';
import './css/featured-in.scss';

const featuredList = [
  {
    id: 'solarNovusToday',
    alt: 'Solar Novus Today',
    src: sntImg,
    link:
      'https://www.solarnovus.com/non-residential-solar-market-financing_N11052.html'
  },
  {
    id: 'yahoo',
    alt: 'Cellcubes Braggawatt makes top 10',
    src: yahooImg,
    link:
      'https://finance.yahoo.com/news/cellcubes-braggawatt-makes-top-10-100000540.html'
  },
  {
    id: 'smartGrid',
    alt: 'Braggawatt uses analytics to help CI invest',
    src: smartGridImg,
    link:
      'https://www.smartgridtoday.com/public/Braggawatt-uses-analytics-to-help-CI-invest-in-DER.cfm'
  },
  {
    id: 'solarBuilder',
    alt: 'Commercial solar financing',
    src: solarBuilderImg,
    link: 'https://solarbuildermag.com/financing/commercial-solar-financing/'
  },
  {
    id: 'cio',
    alt: 'CIO',
    src: cioImg,
    link:
      'https://www.energycioinsights.com/magazines/August2018/Startups/#page=16'
  },
  {
    id: 'businessWire',
    alt: 'Business Wire',
    src: businessWireImg,
    link:
      'https://www.businesswire.com/news/home/20170726005195/en/Braggawatt-Launches-Project-Origination-Financin'
  },
  {
    id: 'solarPowerWorld',
    alt: 'Solar Power World',
    src: solarPowerWorldImg,
    link:
      'https://www.solarpowerworldonline.com/2017/07/braggawatts-online-platform-connects-solar-integrators-customers-investors/'
  }
];

export default class FeaturedIn extends Component {
  shouldComponentUpdate = () => {
    return false;
  };

  render() {
    const { title, companyOverview } = this.props;
    const divCx = cx('m-featuredIn', {
      '-showTitle': title,
      '-companyOverview': companyOverview
    });
    return (
      <section className={divCx}>
        <Head3 data-aos="fade-up" align="center">
          Our innovative solar financing has been featured in:
        </Head3>
        <div className="list">
          {featuredList.map((feature, index) => (
            <a
              data-aos="fade-up"
              data-aos-delay={index * 100}
              key={feature.id}
              href={feature.link}
              name={feature.id}
              rel="noopener noreferrer"
              aria-label={`Read more about ${feature.alt}`}
              target="_blank">
              <Lazy alt={feature.alt} src={feature.src} />
            </a>
          ))}
        </div>
      </section>
    );
  }
}
