import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import InputText from '@bw/library/forms/InputText';
import Head2 from '@bw/library/headings/Head2';
import Button from '@bw/library/Button';
import InputButtonGroup from './InputButtonGroup';
import './css/zipcode-entry.scss';

@inject('Store')
@observer
export default class ZipCodeEntry extends Component {
  static defaultProps = {
    heading: 'See how much you can save with solar.',
    subheading: 'Get a savings estimate in less than 2 minutes.',
    maxWidth: false,
    btnId: 'BTN_ZIPCODE_ENTRY',
    useLocId: 'BTN_USE_LOCATION'
  };

  render() {
    const {
      Store: { Marketing },
      heading,
      subheading,
      btnId,
      useLocId,
      modal,
      minimal,
      maxWidth
    } = this.props;
    const { zipCodeForm, preSubmitZipcode, getLocation } = Marketing;
    return (
      <div
        className={cx('m-zipCodeEntry', {
          '-minimal': minimal,
          '-modal': modal
        })}>
        <div className="m-zipCodeEntry__group">
          <Head2 align="left" data-aos="fade-up">
            {heading}
          </Head2>
          <p>{subheading}</p>
          <div
            className="m-zipCodeEntry__formGroup"
            data-aos="fade-up"
            data-aos-delay="100">
            <InputButtonGroup align="left">
              <InputText
                inputRef={ref => {
                  this.zipInput = ref;
                }}
                type="number"
                maxWidth={maxWidth}
                size="lg"
                showLabel={false}
                placeholder="Enter Zip Code"
                autoComplete="postal-code"
                formField={zipCodeForm.$('zipcode')}
                pattern="[0-9]*"
              />
              <Button
                id={btnId}
                size="lg"
                rightIcon="right-arrow"
                responsive={true}
                label="Let's Go"
                type="primary"
                onClick={preSubmitZipcode}
              />
            </InputButtonGroup>
            <Button
              id={useLocId}
              className="m-zipCodeEntry__useLocationBtn"
              type="simpleWithLine"
              label="Or, use my location"
              onClick={getLocation}
            />
          </div>
        </div>
      </div>
    );
  }
}
