import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'video'
    'form';
  grid-gap: 20px;
  padding: 0 15px;
  max-width: 1260px;
  margin: 20px auto 40px;
  justify-content: center;

  @media (min-width: 992px) {
    grid-template-columns: minmax(280px, 630px) minmax(280px, 511px);
    grid-template-rows: auto;
    grid-template-areas: 'video form';
    margin: 40px auto 40px;
  }

  .video {
    grid-area: video;
    flex: 1;
    max-height: 540px;
  }

  .form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid ${props => props.theme['color-gray2']};
    border-radius: 3px;
    grid-area: form;
    box-shadow: 0 5px 22px 0 rgba(222, 236, 248, 0.81);

    @media (min-width: 992px) {
      padding: 30px;
    }

    .m-zipCodeEntry {
      margin-bottom: 20px;
    }
    > a {
      max-width: 602px;
      margin: auto;
    }
  }
`;
