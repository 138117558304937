import { Router } from 'react-router';
import React from 'react';
import { Provider } from 'mobx-react';
import { hydrate } from 'react-dom';
import Loadable from 'react-loadable';
import App from './App';
import Store from './Stores';
import history from './history';

const root = document.getElementById('root');

window.main = () => {
  render(App);
};

if (module.hot) {
  module.hot.accept('./App', () => {
    const NewApp = require('./App').default;
    render(NewApp);
  });
}

function render(Root) {
  Loadable.preloadReady().then(() => {
    hydrate(
      <Provider Store={Store}>
        <Router history={history}>
          <Root />
        </Router>
      </Provider>,
      root
    );
  });
}
