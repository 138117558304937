import React, { Component } from 'react';
import Loadable from 'react-loadable';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { findDOMNode } from 'react-dom';
import uuidv1 from 'uuid/v1';
import { Element, scroller } from 'react-scroll';

import { track } from '@bw/services/analytics';
import * as C from '@bw/constants';
import Loader2 from '../Loader2';
import playIcon from './images/watch-video-button.svg';
import getParameterByName from '../utils/getParameterByName';

const VideoWrapperStyled = styled.div`
  box-shadow: 0 10px 30px 0 rgba(222, 236, 248, 0.43);
  border: solid 1px ${props => props.theme['color-gray2']};
  margin: 0 auto;
  position: relative;
  padding-top: 56.25%;

  @media (min-width: 992px) {
    margin: 0 0 0 auto;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    max-width: '589px';
    opacity: ${props => (props.visible ? '1' : '0')};
  }
`;

const Thumbnail = styled.div`
  cursor: pointer;
  position: absolute;
  background: ${props => `url(${props.thumbnail}) no-repeat`};
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  display: ${props => (props.visible ? 'none' : 'block')};

  &::after {
    content: '';
    background: url(${playIcon}) no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @media (min-width: 992px) {
    min-height: 325px;
  }
`;

@withRouter
class VideoWrapper extends Component {
  state = {
    play: false,
    autoPlay: false,
    sessionId: uuidv1()
  };

  componentDidMount() {
    const { location } = this.props;

    const play = getParameterByName('play', location.search);

    if (play === '1') {
      setTimeout(() => {
        this.playVideo();
      }, 1000);
    }
  }

  ref = player => {
    this.player = player;
  };

  playVideo = () => {
    this.setState({ autoPlay: true });
    this.scrollToCTA();
    this.onPlay();
  };

  scrollToCTA = () => {
    scroller.scrollTo('video', {
      duration: 500,
      smooth: true,
      offset: -50
    });
  };

  onPlay = () => {
    this.setState(prevState => {
      return {
        play: !prevState.play
      };
    });
  };

  onStart = () => {
    const { screenfull, assetId } = this.props;

    if (isMobileOnly) {
      screenfull.request(findDOMNode(this.player));
    }

    const options = {
      session_id: this.state.sessionId,
      content_asset_id: assetId,
      position: this.player.getSecondsLoaded(),
      total_length: this.player.getDuration()
    };
    track(C.VIDEO_PLAYBACK_STARTED, options);
  };

  onPause = () => {
    const { assetId } = this.props;
    const options = {
      session_id: this.state.sessionId,
      content_asset_id: assetId,
      position: this.player.getSecondsLoaded(),
      total_length: this.player.getDuration()
    };
    track(C.VIDEO_PLAYBACK_PAUSED, options);
  };

  onEnded = () => {
    const { assetId } = this.props;
    const options = {
      session_id: this.state.sessionId,
      content_asset_id: assetId,
      position: this.player.getSecondsLoaded(),
      total_length: this.player.getDuration()
    };
    track(C.VIDEO_PLAYBACK_COMPLETED, options);
  };

  onSeek = seconds => {
    const { assetId } = this.props;
    const options = {
      session_id: this.state.sessionId,
      content_asset_id: assetId,
      position: seconds,
      total_length: this.player.getDuration()
    };
    track(C.VIDEO_PLAYBACK_SEEK, options);
  };

  onError = () => {
    const { assetId } = this.props;
    const options = {
      session_id: this.state.sessionId,
      content_asset_id: assetId,
      total_length: this.player.getDuration()
    };
    track(C.VIDEO_PLAYBACK_INTERRUPTED, options);
  };

  render() {
    const { play, autoPlay } = this.state;
    const { ReactPlayer, url, thumbnail } = this.props;

    return (
      <Element name="video">
        <VideoWrapperStyled visible={play}>
          <Thumbnail
            thumbnail={thumbnail}
            onClick={this.onPlay}
            visible={play}
          />
          <ReactPlayer
            // muted={true}
            playing={play}
            className="react-player"
            controls={true}
            ref={this.ref}
            width="100%"
            height="100%"
            onStart={this.onStart}
            onPause={this.onPause}
            onEnded={this.onEnded}
            onSeek={this.onSeek}
            onError={this.onError}
            url={url}
            config={{
              youtube: {
                playerVars: { showinfo: 0 }
              }
            }}
          />
        </VideoWrapperStyled>
      </Element>
    );
  }
}

export default Loadable.Map({
  loader: {
    ReactPlayer: () =>
      import(/* webpackChunkName: "reactPlayer" */ 'react-player'),
    screenfull: () => import(/* webpackChunkName: "screenfull" */ 'screenfull')
  },
  loading: Loader2,
  render(loaded, props) {
    const ReactPlayer = loaded.ReactPlayer.default;
    const screenfull = loaded.screenfull.default;

    return (
      <VideoWrapper
        ReactPlayer={ReactPlayer}
        screenfull={screenfull}
        {...props}
      />
    );
  }
});
