import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import cx from 'classnames';

import DropdownTrigger from './DropdownTrigger';
import DropdownContent from './DropdownContent';
import './css/dropdown.scss';

class Dropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    removeElement: PropTypes.bool,
    style: PropTypes.object,
    minimal: PropTypes.bool,
    navigation: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    minimal: false,
    navigation: false
  };

  static displayName = 'Dropdown';

  constructor(props) {
    super(props);

    this.state = {
      active: props.active
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
    window.addEventListener('touchstart', this.onWindowClick);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.props.active) {
      this.setState({ active: nextProps.active });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('touchstart', this.onWindowClick);
  }

  onWindowClick = event => {
    const dropdownElement = findDOMNode(this);

    if (
      event.target !== dropdownElement &&
      !dropdownElement.contains(event.target) &&
      this.state.active
    ) {
      this.hide();
    }
  };

  onToggleClick = event => {
    event.preventDefault();
    if (this.state.active) {
      this.hide();
    } else {
      this.show();
    }
  };

  hide() {
    this.setState(
      {
        active: false
      },
      () => {
        if (this.props.onHide) {
          this.props.onHide();
        }
      }
    );
  }

  show() {
    this.setState(
      {
        active: true
      },
      () => {
        if (this.props.onShow) {
          this.props.onShow();
        }
      }
    );
  }

  render() {
    const {
      hideArrow,
      children,
      className,
      disabled,
      removeElement,
      minimal,
      navigation
    } = this.props;
    // create component classes
    const { active } = this.state;
    const dropdownClasses = cx({
      'a-dropdown': true,
      '-active': active,
      '-disabled': disabled,
      '-minimal': minimal,
      '-navigation': navigation,
      '-hideArrow': hideArrow
    });
    // stick callback on trigger element
    const boundChildren = React.Children.map(children, (child, ...rest) => {
      if (child.type === DropdownTrigger) {
        const originalOnClick = child.props.onClick;
        child = cloneElement(child, {
          ref: 'trigger',
          onClick: event => {
            if (!disabled) {
              this.onToggleClick(event);
              if (originalOnClick) {
                originalOnClick.apply(child, rest);
              }
            }
          }
        });
      } else if (child.type === DropdownContent && removeElement && !active) {
        child = null;
      }
      return child;
    });
    const cleanProps = { ...this.props };
    delete cleanProps.active;
    delete cleanProps.onShow;
    delete cleanProps.onHide;
    delete cleanProps.removeElement;
    delete cleanProps.minimal;
    delete cleanProps.navigation;
    delete cleanProps.hideArrow;

    return (
      <div {...cleanProps} className={`${dropdownClasses} ${className}`}>
        {boundChildren}
      </div>
    );
  }
}

export { DropdownTrigger, DropdownContent };
export default Dropdown;
