import React from 'react';
import { Link } from 'react-router-dom';
import { api } from '@bw/config';

export default ({ directLink, to, children }) => {
  return directLink ? (
    <a className="o-solarInstaller" href={`${api.marketingURL}${to}`}>
      {children}
    </a>
  ) : (
    <Link className="o-solarInstaller" to={to}>
      {children}
    </Link>
  );
};
