import { types, flow } from 'mobx-state-tree';
import MobxReactForm from 'mobx-react-form';
import plugins, {
  globalOptions as options
} from '@bw/modules/validationPluginConfig';

import { track } from '@bw/services/analytics';
import * as services from './services';
import { SIGNED_UP } from '@bw/constants';
import fields, { integratorForm } from './forms/fields';
import { api } from '@bw/config';

const MarketingStore = types
  .model('MarketingStore', {
    formSubmitted: types.optional(types.boolean, false)
  })
  .actions(self => ({
    afterCreate() {
      const hooks = {
        onSuccess(response) {
          self.submitZipCode(response.values());
        }
      };

      const integratorHooks = {
        onSuccess(response) {
          self.submitIntegrator(response.values());
        }
      };

      /* Actual initialization of the mobx-react-form */
      self.zipCodeForm = new MobxReactForm(
        { fields },
        { options, plugins, hooks }
      );
      self.integratorForm = new MobxReactForm(
        { fields: integratorForm },
        { options, plugins, hooks: integratorHooks }
      );
    },

    submitZipCode(values) {
      window.location = `${api.appURL}/enterprises/hello?zipcode=${
        values.zipcode
      }`;
    },

    getLocation() {
      window.location = `${api.appURL}/enterprises/hello?loc=1`;
    },

    submitIntegrator: flow(function* submitIntegrator(values) {
      const fullName = `${values.firstName} ${values.lastName}`;

      values.name = fullName.trim();

      try {
        yield services.submitIntegrator(values);
        const segmentProps = {
          type: 'INTEGRATOR',
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone,
          website: values.website
        };
        track(SIGNED_UP, segmentProps);
        self.formSubmitted = true;
      } catch (error) {
        console.log(error);
      }
    }),

    preSubmitIntegrator() {
      self.integratorForm.submit();
    },

    preSubmitZipcode() {
      self.zipCodeForm.submit();
    }
  }));

export const MarketingStoreCreate = MarketingStore.create();
export default MarketingStore;
