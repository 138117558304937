/* Button Component */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { titleCase } from 'change-case';
import cx from 'classnames';

import Icon from './Icon';
import './css/button.scss';

const ToggleVisibility = posed.div({
  hide: {
    opacity: 0
  },
  show: {
    opacity: 1
  }
});

export default class Button extends PureComponent {
  btnRef = null;

  static propTypes = {
    buttonType: PropTypes.oneOf(['button', 'submit']),
    type: PropTypes.oneOf([
      'icon',
      'minimal',
      'modal',
      'primary', // yellowish bg
      'secondary', // turquoise
      'important', // red color
      'simple',
      'simpleWithLine'
    ]),

    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    iconImg: PropTypes.string,
    inline: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    state: PropTypes.oneOf(['pending', 'fulfilled', 'rejected', 'error'])
  };

  static defaultProps = {
    buttonType: 'button',
    type: 'secondary'
  };

  renderContent = () => {
    const { icon, iconImg, state } = this.props;

    return (
      <div className="a-button__container">
        {icon && <i className={`a-button__icon bw-icon--${icon}`} />}
        {iconImg && (
          <img className="a-button__imgIcon" src={iconImg} alt="img" />
        )}
        {state === 'pending' && (
          <ToggleVisibility
            className="a-button__loaderContainer"
            pose={state === 'pending' ? 'show' : 'hide'}>
            <div className="a-button__loader" />
          </ToggleVisibility>
        )}

        <ToggleVisibility
          className="a-button__labelContainer"
          pose={state === 'pending' ? 'hide' : 'show'}>
          {this.renderLabel()}
        </ToggleVisibility>
      </div>
    );
  };

  renderLabel = () => {
    const { label, children, rightIcon, rightImg } = this.props;
    if (rightIcon) {
      return (
        <Fragment>
          <span>{label || children}</span> <Icon icon={rightIcon} />
        </Fragment>
      );
    }

    if (rightImg) {
      return (
        <Fragment>
          {label || children}{' '}
          <img className="a-button__imgRight" src={rightImg} alt="img" />
        </Fragment>
      );
    }
    return label || children;
  };

  render() {
    const {
      id,
      buttonType,
      color,
      cy,
      className,
      disabled,
      inline,
      onClick,
      responsive,
      size,
      state,
      type,
      rightIcon,
      rightImg,
      iconImg,
      hasMinWidth,
      ...rest
    } = this.props;

    const buttonCx = cx('a-button', {
      [`-${type}`]: type,
      [`-${state}`]: state,
      [`-size${titleCase(size)}`]: size,
      [className]: className,
      '-responsive': responsive,
      '-inline': inline,
      [`-${color}Color`]: color,
      '-hasRightIcon': rightIcon
    });

    return (
      <button
        data-cy={cy}
        id={id}
        ref={ref => (this.btnRef = ref)}
        className={buttonCx}
        type={buttonType}
        onClick={onClick}
        disabled={disabled}
        {...rest}>
        {this.renderContent()}
      </button>
    );
  }
}
