import styled from 'styled-components';

export default styled.label`
  min-width: 80px;
  min-height: 22px;
  color: ${props =>
    props.disabled ? props.theme['color-gray2'] : props.theme['color-gray5']};
  font-size: 14px;
  font-weight: bold;
  margin-right: 30px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
