import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';

import arrowSvg from '../images/golden-arrow.svg';

@withRouter
@observer
export default class CTALink extends Component {
  static defaultProps = {
    arrow: true
  };
  render() {
    const { label, link, arrow } = this.props;
    return (
      <Link to={link}>
        {label} {arrow && <img src={arrowSvg} alt={label} />}
      </Link>
    );
  }
}
