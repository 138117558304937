export default [{ name: 'zipcode', label: 'Zip Code', rules: 'required' }];

export const integratorForm = [
  { name: 'firstName', label: 'First name', rules: 'required' },
  { name: 'lastName', label: 'Last name', rules: 'required' },
  { name: 'website', label: 'Website', rules: 'required' },
  { name: 'email', label: 'Email address', rules: 'required' },
  { name: 'phone', label: 'Phone number', rules: 'required' },
  { name: 'name', label: 'Name' }
];
