import caseStudyHeroImg from '@bw/library/images/case-study-hero-mobile-1125-px.jpg';
import solarInvertersImg from '@bw/library/images/solar-inverters.jpg';
import caseStudyTabletImg from '@bw/library/images/sjfmc-full-solar-panels-tablet-1300-px.jpg';
import howMuchSolarImg from '@bw/library/images/how-much-solar.jpg';
import financialBenefitImg from '@bw/library/images/financial-benefit.jpg';
import financingOptionImg from '@bw/library/images/financing-options.jpg';
import processSolarImg from '@bw/library/images/process-solar.jpg';
import treyImg from './images/about/trey.jpg';
import easanImg from './images/about/easan.jpg';
import olegImg from './images/about/oleg.jpg';
import kiiImg from './images/about/kii.jpg';

export const TEAM_MEMBERS = [
  {
    id: 1,
    name: 'Trey Ramsey',
    title: 'Chief Executive Officer',
    thumb: treyImg,
    url: 'https://www.linkedin.com/in/trey-ramsey-4017a011/'
  },
  {
    id: 2,
    name: 'Kii Miller',
    title: 'Chief Operating Officer',
    thumb: kiiImg,
    url: 'https://www.linkedin.com/in/nathan-kii-miller-b696301/'
  },
  {
    id: 3,
    name: 'Oleg Popovsky',
    title: 'Chief Customer Officer',
    thumb: olegImg,
    url: 'https://www.linkedin.com/in/oleg-popovsky-b8598a/'
  },
  {
    id: 4,
    name: 'Easan Drury',
    title: 'Chief Technology Officer',
    thumb: easanImg,
    url: 'https://www.linkedin.com/in/easandrury/'
  }
];

export const READ_MORE_WHY_BUSINESS_GO_SOLAR = {
  id: 'WHY_BUSINESS_GO_SOLAR',
  header: 'Why Do Businesses Go Solar?',
  text:
    'Learn how businesses can benefit from installing solar on their property.',
  readMoreLabel: 'Read More',
  link: '/small-business-solar',
  src: caseStudyHeroImg
};

export const readMoreCaseStudy = type => {
  return {
    id: 'CASE_STUDY',
    header: 'Our Projects',
    text:
      type === 'nonprofit'
        ? 'See how we have helped nonprofits save with solar.'
        : 'See how we have lead projects from start to finish in 100 days or less.',
    readMoreLabel: 'Read More',
    link: '/case-studies',
    src: caseStudyTabletImg
  };
};

export const readMoreSolarWork = type => {
  return {
    id: 'HOW_DOES_SOLAR_WORK',
    header: 'How do Solar Energy Systems Work?',
    text:
      type === 'nonprofit'
        ? 'Learn more about solar can produce energy for your nonprofit.'
        : 'Learn more about how solar can produce energy for your business.',
    readMoreLabel: 'Read More',
    link: '/how-solar-energy-works',
    src: solarInvertersImg
  };
};

export const readMoreSolarProcess = type => {
  return {
    id: 'PROCESS_FOR_GOING_SOLAR',
    header: 'Our Process',
    text:
      type === 'nonprofit'
        ? 'We take nonprofit projects from start to finish in 100 days or less.'
        : 'Achieve results worth bragging about in three simple steps!',
    readMoreLabel: 'Read More',
    link: '/braggawatt-solar-process',
    src: processSolarImg
  };
};

export const READ_MORE_FINANCING_OPTIONS = {
  id: 'FINANCING_OPTIONS',
  header: 'Solar Financing Options',
  text: 'Which option will be best for your business? Find out today.',
  readMoreLabel: 'Read More',
  link: '/solar-financing-for-business-and-nonprofits',
  src: financingOptionImg
};

export const READ_MORE_SOLAR_PANELS = {
  id: 'SOLAR_PANELS',
  header: 'What if my Solar System Produces more Energy than I Need?',
  text:
    'Learn more about Net Metering, a mechanism used by utilities to pay you for excess solar generation.',
  readMoreLabel: 'Read More',
  link: '/solar-financial-benefits#NET_METERING',
  src: howMuchSolarImg
};

export const READ_MORE_FINANCIAL_BENEFITS = {
  id: 'FINANCIAL_BENEFITS',
  header: 'Financial benefits of solar',
  text:
    'There are many immediate benefits from going solar, but the key question is: can solar improve my bottom line?',
  readMoreLabel: 'Read More',
  link: '/solar-financial-benefits',
  src: financialBenefitImg
};
