import styled from 'styled-components';

export default styled.div`
  margin: 0 auto;

  picture {
    source,
    img {
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
`;
