import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import WithFooter from '@bw/library/footer/WithFooter';
import ZipCodeEntry from '@bw/modules/Marketing/components/ZipCodeEntry';
import BlockStats from './BlockStats';
import Gallery from './Gallery';
import PhotoQuote from './PhotoQuote';
import Quote from './Quote';
import StyledBlockStats from './StyledBlockStats';
import StyledBuildingGrid from './StyledBuildingGrid';
import StyledHeader from './StyledHeader';
import StyledHero from './StyledHero';
import StyledLowerGrid from './StyledLowerGrid';
import StyledQuoteWrapper from './StyledQuoteWrapper';
import heroMobile from '../images/sjfmc/case-study-hero-mobile-1125px.jpg';
import heroTablet from '../images/sjfmc/case-study-hero-tablet-1600px.jpg';
import heroDesktop from '../images/sjfmc/case-study-hero-desktop-2880px.jpg';
import buildingMobile from '../images/sjfmc/building-front-mobile-900px.jpg';
import buildingTablet from '../images/sjfmc/building-front-tablet-1300px.jpg';
import buildingDesktop from '../images/sjfmc/building-front-desktop-2600px.jpg';
import guysPlanningSm from '../images/sjfmc/guys-planning-tablet-1000px.jpg';
import garyAvatar from '../images/sjfmc/gary-defilippo.jpg';
import ronaldAvatar from '../images/sjfmc/ronald-kaplan-portrait.png';
import StyledSignificantSavings from './StyledSignificantSavings';
import StyledCaseStudy from './StyledCaseStudy';

export default class SouthernJersey extends Component {
  render() {
    return (
      <WithFooter background={true}>
        <StyledCaseStudy>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="Solar financing for business is now easier than ever, with expertise from Braggawatt. Take advantage of the benefits of solar for your business or Nonprofit today. Learn more."
            />
            <title>
              Benefits of Solar Energy & Financing Solar for Business
            </title>
          </Helmet>
          <StyledHero>
            <picture>
              <source media="(max-width: 576px)" srcSet={heroMobile} />
              <source media="(max-width: 768px)" srcSet={heroTablet} />
              <img src={heroDesktop} alt="Solar panels" />
            </picture>
            <div className="gradient" />
          </StyledHero>
          <StyledHeader data-aos="fade-right" data-aos-delay={200}>
            <div className="inner">
              Southern Jersey Family Medical Centers, Inc. (SJFMC)
              <div className="loc">Burlington, NJ</div>
            </div>
          </StyledHeader>
          <Quote>
            Braggawatt quickly evaluated our unique savings potential and
            produced financing terms that no other provider was able to offer.
          </Quote>
          <StyledBuildingGrid>
            <div className="building" data-aos="zoom-out">
              <picture>
                <source media="(max-width: 576px)" srcSet={buildingMobile} />
                <source media="(max-width: 768px)" srcSet={buildingTablet} />
                <img src={buildingDesktop} alt="Solar building project" />
              </picture>
            </div>

            <div className="descWrapper">
              <div className="desc" data-aos="fade-right">
                <p>
                  For Ronald Kaplan of SJFMC, solar was a viable solution to
                  control rising electricity costs, but he struggled to secure
                  nonprofit solar financing without any upfront investment.
                  Thankfully, he found Braggawatt.{' '}
                </p>

                <p>
                  Within 100 days of contacting us, the medical center had an
                  operational solar energy system installed, which was 100%
                  funded through Braggawatt’s Power Purchase Agreement (PPA)
                  (link to financing and ownership options), and were well on
                  their way to lowering their electricity costs by 16%.
                </p>

                <p>
                  Besides decreasing its annual electricity bill, SJFMC has
                  demonstrated its commitment to the community and the world.
                  Their solar panel system is contributing to a healthier
                  environment and is supporting the local economy through a
                  long-standing relationship with{' '}
                  <a href="http://www.energysolutionsnj.com/" target="_blank">
                    Energy Solutions of New Jersey (ESNJ)
                  </a>
                  .
                </p>
              </div>
            </div>
            <StyledQuoteWrapper className="-ronald">
              <PhotoQuote
                offsetYMax="-100px"
                offsetYMin="100px"
                avatar={ronaldAvatar}
                className="-ronald"
                person="Ronald Kaplan"
                title="CFO, SJFMC">
                "We tried to go solar for over two years and had pretty much
                given up. It seemed like no one would finance a small system for
                a non-profit. Braggawatt quickly evaluated our unique savings
                potential and produced financing terms that no other provider
                was able to stand behind. They said yes when everyone else said
                no."
              </PhotoQuote>
            </StyledQuoteWrapper>
          </StyledBuildingGrid>
          <StyledSignificantSavings data-aos="fade-up">
            <div className="header">Savings with Significance</div>
            <div className="text">
              Since going solar, SJFMC has seen meaningful overall savings -
              both financial and environmental - which they’ve been able to
              reinvest in their nonprofit’s goal: offering patients the best,
              most responsible care.
            </div>
          </StyledSignificantSavings>
          <StyledBlockStats>
            <div className="title">SJFMC Year 1 Results</div>
            <div className="wrapper">
              <BlockStats
                value="15%"
                label="Electricity from Solar"
                delay={200}
              />
              <BlockStats
                value="37%"
                label="Reduction in Electricity Costs (per kWh)"
                delay={400}
              />
              <BlockStats
                value="22.1"
                label="Tons of CO2 Not Emitted"
                delay={600}
              />
              <BlockStats
                value="75,048"
                label="Total kWh of Energy Produced"
                delay={900}
              />
            </div>
          </StyledBlockStats>
          <StyledLowerGrid>
            <div className="inner">
              <div className="headerGroup">
                <div className="header" data-aos="fade-up">
                  Partnerships that Pay Off
                </div>
                <div className="text" data-aos="fade-up">
                  <p>
                    When SJFMC partnered with ESNJ, a local energy contracting
                    service provider who knew the Burlington site, ESNJ
                    suggested reaching out to Braggawatt in a last-ditch effort
                    to find nonprofit solar panel financing.
                  </p>
                  <p>
                    Braggawatt is proud to partner with{' '}
                    <Link to="/solar-integrator-financing-platform">
                      local installers
                    </Link>{' '}
                    like ESNJ across the country to keep jobs in the communities
                    where projects are built.
                  </p>
                </div>
              </div>
            </div>
            <div className="guysPlanning">
              <div
                className="guysPlanning__inner"
                style={{ backgroundImage: `url(${guysPlanningSm})` }}
              />
            </div>
            <StyledQuoteWrapper className="-gary">
              <PhotoQuote
                offsetYMax="0px"
                offsetYMin="200px"
                avatar={garyAvatar}
                className="-gary"
                person="Gary DeFillippo"
                title="President, ESNJ">
                "Braggawatt's online financing process has filled the gap for
                small and medium-sized commercial customers. We are excited to
                unlock many more SME projects using Braggawatt's free online
                platform, which instills great confidence in our team and
                customers."
              </PhotoQuote>
            </StyledQuoteWrapper>
          </StyledLowerGrid>
          <Gallery />
        </StyledCaseStudy>
      </WithFooter>
    );
  }
}
