import React, { Component } from 'react';
import cx from 'classnames';
import './css/input-button-group.scss';

export default class InputButtonGroup extends Component {
  render() {
    const { children, align } = this.props;
    const divCx = cx('a-inputButtonGroup', {
      [`-${align}`]: align
    });
    return <div className={divCx}>{children}</div>;
  }
}
