import React, { Component } from 'react';
import './page-not-found.scss';

// TODO: Add previous route and ping Slack / Sentry
export default class PageNotFound extends Component {
  render() {
    return (
      <div className="m-pageNotFound">
        <div className="m-pageNotFound__bigLabel">404</div>
        <div className="m-pageNotFound__smallLabel">PAGE NOT FOUND</div>
        <div className="m-pageNotFound__messaging">
          The page does not exist or it may have been removed.
        </div>
        <div className="m-pageNotFound__action">
          Go back to <a href="/">homepage.</a>
        </div>
      </div>
    );
  }
}
