import React from 'react';
import './css/quote.scss';

export default ({ children }) => {
  return (
    <div className="m-quote" data-aos="fade-up" data-aos-offset="200">
      <div className="m-quote__inner">{children}</div>
    </div>
  );
};
