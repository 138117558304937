import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import Label from './Label';
import Input from './Input';
import InputErrorField from './InputErrorField';
import ErrorTextDisplay from '../animations/ErrorTextDisplay';
import errorIcon from './css/images/np-error.svg';
import './css/input-text.scss';

/**
 * This component is meant to be used with mobx-react-form.
 */
@observer
export default class InputText extends Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    className: PropTypes.string,
    formField: PropTypes.object.isRequired,
    id: PropTypes.string,
    inputClassName: PropTypes.string,
    inputStyle: PropTypes.object,
    label: PropTypes.string,
    maxWidth: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    showLabel: PropTypes.bool,
    showErrors: PropTypes.bool,
    size: PropTypes.string,
    suffix: PropTypes.string,
    tooltip: PropTypes.string,
    type: PropTypes.string
  };

  static defaultProps = {
    type: 'text',
    showLabel: true,
    showErrors: true
  };

  constructor(props) {
    super(props);
    this.state = {
      readOnly: props.readOnly
    };
  }

  onChange = event => {
    const { formField } = this.props;

    formField.bind().onChange(event);
    if (this.props.onChange) this.props.onChange(event);
  };

  onBlur = event => {
    const { formField } = this.props;

    formField.bind().onBlur(event);
    if (this.props.onBlur) this.props.onBlur(event);
  };

  toggleEdit = () => {
    this.setState(prevState => {
      return {
        readOnly: !prevState.readOnly
      };
    });
  };

  render() {
    const { readOnly } = this.state;
    const {
      autoComplete,
      className,
      disabled,
      formField,
      hasSearchIcon,
      id,
      icon,
      inputClassName,
      inputRef,
      inputStyle,
      label,
      max,
      maxWidth,
      min,
      onKeyDown,
      onKeyUp,
      placeholder,
      prefix,
      required,
      showErrors,
      showLabel,
      size,
      tooltip,
      type,
      inlineEdit,
      pattern,
      inputMode
    } = this.props;

    const containerCx = cx('a-inputText', {
      [className]: className,
      '-error': formField.error,
      '-disabled': disabled
    });

    const inputContainerCx = cx('a-inputText__inputContainer', {
      '-hasSearchIcon': hasSearchIcon,
      '-maxWidth': maxWidth
    });

    const inputCx = cx({
      [inputClassName]: inputClassName,
      [`-${size}`]: size,
      '-hasPrefix': prefix
    });

    const displayedLabel = label || formField.label;

    return (
      <div className={containerCx}>
        {displayedLabel && showLabel && (
          <Label htmlFor={formField.id}>
            {displayedLabel} {required && '*'}{' '}
            {tooltip && <Tooltip label={tooltip} />}
          </Label>
        )}

        <div className={inputContainerCx}>
          {icon && <i className={`bw-icon--${icon}`} />}
          {prefix && <span className="a-inputText__prefix">{prefix}</span>}
          <Input
            {...formField.bind()}
            id={id}
            autoComplete={autoComplete}
            className={inputCx}
            disabled={disabled}
            max={max}
            min={min}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={inputRef}
            style={inputStyle}
            type={type}
            aria-label={`${formField.name} input`}
            pattern={pattern}
            inputMode={inputMode}
          />
          <img
            className={cx('a-inputText__errorIcon', {
              '-error': formField.error
            })}
            src={errorIcon}
            alt={`error - ${formField.error}`}
          />
          {inlineEdit && (
            <Icon
              icon="edit"
              cursorPointer={true}
              onClick={this.toggleEdit}
              className="a-inputText__edit"
            />
          )}
          {showErrors && (
            <ErrorTextDisplay pose={formField.error ? 'open' : 'closed'}>
              <InputErrorField
                text={formField.error}
                cy={id || formField.name}
              />
            </ErrorTextDisplay>
          )}
        </div>
      </div>
    );
  }
}
