import { types } from 'mobx-state-tree';
import AppStore, { AppStoreCreate } from '@bw/modules/App/Store';
import MarketingStore, {
  MarketingStoreCreate
} from '@bw/modules/Marketing/Store';

const root = types.model('RootStore', {
  App: AppStore,
  Marketing: MarketingStore
});

export default root.create({
  App: AppStoreCreate,
  Marketing: MarketingStoreCreate
});
