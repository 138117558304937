import React from 'react';
import Head2 from '@bw/library/headings/Head2';
import roofSolarPanels2x from '../images/wildwood/DSC_0304.JPG';
import roofSolarPanelsSide2x from '../images/wildwood/DSC_0329.JPG';
import solarInverters2x from '../images/wildwood/DSC_0344.JPG';
import guysOnSolarRooftop from '../images/wildwood/DSC_0374.JPG';

import wholeSolarPanels2x from '../images/wildwood/DSC_0339.JPG';
import './css/gallery.scss';
export default () => {
  return (
    <div className="m-gallery">
      <Head2>Project Photo Gallery</Head2>
      <div className="m-gallery__group">
        <div
          className="m-gallery__image -one"
          data-aos="zoom-out"
          data-aos-delay="100">
          <picture>
            <img src={roofSolarPanels2x} alt="Roof solar panels" />
          </picture>
        </div>

        <div
          className="m-gallery__image -two"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <img src={roofSolarPanelsSide2x} alt="Roof solar panels side" />
          </picture>
        </div>

        <div
          className="m-gallery__image -three"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <img src={solarInverters2x} alt="Solar Inverters" />
          </picture>
        </div>

        <div
          className="m-gallery__image -four"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <img src={guysOnSolarRooftop} alt="Guys on solar panel roof" />
          </picture>
        </div>

        <div
          className="m-gallery__image -five"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <img src={wholeSolarPanels2x} alt="Whole solar panels" />
          </picture>
        </div>
      </div>
    </div>
  );
};
