import React, { PureComponent } from 'react';
import './css/form-title.scss';

export default class FormTitle extends PureComponent {
  render() {
    return (
      <div className="a-formTitle">
        {this.props.label || this.props.children}
      </div>
    );
  }
}
