import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import posed from 'react-pose';
import cx from 'classnames';

import { api } from '@bw/config';
import WhyBwDropdown from './WhyBwDropdown';
import WhySolarDropdown from './WhySolarDropdown';
import Icon from '../Icon';
import AccordionButton from '../buttons/AccordionButton';
import Button from '../Button';
import BWLink from './BWLink';

import './css/mobile-menu-dropdown.scss';

const MobileMenu = posed.div({
  hide: {
    applyAtEnd: { display: 'none' },
    y: -400,
    transition: {
      y: {
        type: 'tween',
        ease: 'easeOut'
      }
    }
  },
  show: {
    applyAtStart: { display: 'block' },
    y: -50,
    transition: {
      y: {
        type: 'tween',
        ease: 'easeOut'
      }
    }
  }
});

const Content = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' }
});

@inject('Store')
@observer
export default class MobileMenuDropdown extends Component {
  switchMenu = (event, type) => {
    const {
      Store: { App }
    } = this.props;
    const { switchMobileMenuView } = App.ui;
    switchMobileMenuView(type);
    event.stopPropagation();
  };

  render() {
    const {
      Store: { App },
      directLink,
      isAuthenticated,
      handleReturningUser
    } = this.props;
    const { toggleMobileMenu, mobileMenu, mobileMenuView } = App.ui;

    return (
      <Fragment>
        <MobileMenu
          className="m-mobileMenuDropdown"
          pose={mobileMenu ? 'show' : 'hide'}>
          <div className="m-mobileMenuDropdown__wrapper">
            <div
              className="m-mobileMenuDropdown__close"
              onClick={toggleMobileMenu}>
              <Icon icon="close" />
            </div>
            <div className="m-mobileMenuDropdown__group">
              <AccordionButton
                active={mobileMenuView === 'whySolar'}
                label="Why Solar?"
                onClick={event => this.switchMenu(event, 'whySolar')}
              />
              <Content
                className="m-mobileMenuDropdown__content"
                pose={mobileMenuView === 'whySolar' ? 'open' : 'closed'}>
                <div
                  className="m-mobileMenuDropdown__contentInner"
                  onClick={toggleMobileMenu}>
                  <WhySolarDropdown directLink={true} />
                </div>
              </Content>
            </div>

            <div className="m-mobileMenuDropdown__group">
              <BWLink to="/solar-for-business" directLink={directLink}>
                For Business
              </BWLink>
            </div>

            <div className="m-mobileMenuDropdown__group">
              <BWLink to="/solar-for-nonprofit" directLink={directLink}>
                For Nonprofit
              </BWLink>
            </div>

            <div className="m-mobileMenuDropdown__group">
              <AccordionButton
                active={mobileMenuView === 'whyBw'}
                label="Our Process"
                onClick={event => this.switchMenu(event, 'whyBw')}
              />
              <Content
                className="m-mobileMenuDropdown__content"
                pose={mobileMenuView === 'whyBw' ? 'open' : 'closed'}>
                <div
                  className="m-mobileMenuDropdown__contentInner"
                  onClick={toggleMobileMenu}>
                  <WhyBwDropdown directLink={true} />
                </div>
              </Content>
            </div>

            <div className="m-mobileMenuDropdown__group">
              <BWLink
                to="/solar-integrator-financing-platform"
                directLink={directLink}>
                Solar Installers
              </BWLink>
            </div>

            {isAuthenticated ? (
              <div onClick={toggleMobileMenu}>
                <Button
                  className="m-mobileMenuDropdown__returnToApp"
                  onClick={handleReturningUser}
                  label="Return to App"
                  type="simple"
                />
              </div>
            ) : (
              <div onClick={toggleMobileMenu}>
                {directLink ? (
                  <Link className="m-mobileMenuDropdown__login" to="/login">
                    Login
                  </Link>
                ) : (
                  <a
                    className="m-mobileMenuDropdown__login"
                    href={`${api.appURL}/login`}>
                    Login
                  </a>
                )}
              </div>
            )}
          </div>
        </MobileMenu>
        <div
          onClick={toggleMobileMenu}
          className={cx('m-mobileMenuDropdown__backdrop', {
            '-open': mobileMenu
          })}
        />
      </Fragment>
    );
  }
}
