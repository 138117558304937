import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import rules from './validationCustomRules';
import asyncRules from './validationAsyncRules';

export const globalOptions = {
  validateOnChange: true,
  autoParseNumbers: true,
  retrieveOnlyEnabledFields: true,
  showErrorsOnBlur: false
  // strictUpdate: true
};

export default {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }) => {
      const messages = validator.getMessages('en');
      messages.required = ':attribute is required.';
      /* Initiates all of the custom rules and validation */
      Object.keys(rules).forEach(key => {
        if (rules[key].customFunction) {
          validator.register(
            key,
            rules[key].customFunction,
            rules[key].message
          );
        }
      });

      /* Initialize all async rules */
      Object.keys(asyncRules).forEach(key =>
        validator.registerAsync(key, asyncRules[key])
      );

      validator.setMessages('en', messages);
    }
  })
};
