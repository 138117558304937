import styled from 'styled-components';
import desktopBg from '../../images/solar-firestation.jpg';

export default styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${desktopBg});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px 16px;
  min-height: calc(100vh - 58px);
  max-height: 900px;
  transition: background-position 0.2s;
  color: white;
  background-position: right 0px;

  @media (min-width: 576px) {
    padding: 38px;
  }

  @media (min-width: 992px) {
    background-position: right bottom;
  }

  @media (min-width: 2200px) {
    background-position: right -400px;
  }

  .extraTop {
    height: 298px;
    background-image: linear-gradient(
      to bottom,
      #a1b7e2,
      rgba(161, 183, 226, 0)
    );
  }

  .header {
    font-size: 24px;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 32px;
    max-width: 1140px;
    margin: 0 auto 32px;

    @media (min-width: ${({ theme }) => theme['res-md']}) {
      margin-top: 40px;
    }

    h1 {
      text-align: center !important;
    }
  }

  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
  }

  .offers {
    display: flex;
    justify-content: center;
    align-items: center;

    .offers__arrow {
      margin-top: -21px;
    }

    .offers__item {
      padding: 0 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 576px) {
        padding: 0 20px;
      }

      > div:first-child {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;

        @media (min-width: 576px) {
          height: 80px;
          width: 80px;
        }

        > div {
          height: 24px;
          width: 100%;

          @media (min-width: 576px) {
            height: 37px;
          }
        }
      }

      svg {
        max-height: 24px;

        @media (min-width: 576px) {
          max-height: 37px;
        }
      }
    }
  }

  .cta {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @media (min-width: 576px) {
    }

    a + a {
      margin-left: 20px;
    }
  }
`;
