import React from 'react';

import Slider from 'react-slick';

//-- Slick Carousel --
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './quoteSlider.scss';

//-- read quote data --
const quoteData = require('./quotes.json');

//=== HELPER FUNCTIONS ===
function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', color: 'darkgrey' }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', color: 'darkgrey' }}
      onClick={onClick}
    />
  );
}

function FormattedQuote(props) {
  const { quote, author, position, year, img } = props;
  return (
    <div className="quote-slider-single">
      <p className="quote-slider-copy">
        <span className="quote-slider-quotation">"</span>
        {quote}
        <span className="quote-slider-quotation">"</span>
      </p>
      <p className="quote-slider-author">{author}</p>
      <p className="quote-slider-position">{position}</p>
    </div>
  );
}

function QuoteSlider(props) {
  //-- Get Array of Quote Components --
  const quotes = [];
  for (let i = 0; i < quoteData.length; i++) {
    quotes.push(
      <FormattedQuote
        key={i}
        quote={quoteData[i].quote}
        author={quoteData[i].author}
        position={quoteData[i].position}
        year={quoteData[i].year}
        img={quoteData[i].img}
      />
    );
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    swipeToSlide: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  return (
    <div id="quote-slider-main">
      <Slider {...sliderSettings}>{quotes}</Slider>
    </div>
  );
}

export default QuoteSlider;
