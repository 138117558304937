import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import tooltipImg from './images/tooltip.svg';
import './css/tooltip.scss';

export default class Tooltip extends Component {
  static propTypes = {
    inline: PropTypes.bool
  };

  state = {
    imgUrl: tooltipImg
  };

  render() {
    const { imgUrl } = this.state;
    const { label, inline, direction } = this.props;
    const containerCx = cx('a-tooltip', {
      '-inline': inline,
      '-left': direction === 'left'
    });
    return (
      <div className={containerCx}>
        <img src={imgUrl} alt="" />
        <div className="a-tooltip__hoverContent">
          <div>{label}</div>
        </div>
      </div>
    );
  }
}
