import React, { Component } from 'react';
import posed from 'react-pose';
import cx from 'classnames';
import './css/description.scss';

const Content = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' }
});

export default class Description extends Component {
  static defaultProps = {
    open: false
  };

  constructor(props) {
    super(props);

    this.state = {
      open: ['CLEAN_GREEN', 'REDUCE_ELECTRICITY', 'BUILD_TRUST'].includes(
        props.id
      )
    };
  }

  toggleContent = () => {
    this.setState(previous => {
      return {
        open: !previous.open
      };
    });
  };

  render() {
    const { open } = this.state;
    const { header, text } = this.props;
    const iconCx = cx('header__icon', {
      '-open': open
    });
    return (
      <div className="a-description" onClick={this.toggleContent}>
        <div className="header">
          <div className={iconCx}>+</div>
          <div className="header__label">{header}</div>
        </div>
        <Content className="text" pose={open ? 'open' : 'closed'}>
          <div>{text}</div>
        </Content>
      </div>
    );
  }
}
