import { apiPromise } from '@bw/services/apiRequest';
/**
 * @returns {Promise}
 */
export function getProjectLocations() {
  const request = {
    method: 'GET',
    url: '/project_locations'
  };

  return apiPromise(request);
}
