import React, { Component } from 'react';
import SimpleFooter from './SimpleFooter';

export default class WithFooter extends Component {
  render() {
    const { children, className, hideMobile, background } = this.props;
    return (
      <div className={className}>
        {children}
        <SimpleFooter hideMobile={hideMobile} background={background} />
      </div>
    );
  }
}
