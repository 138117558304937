import React, { Fragment } from 'react';
import BWLink from './BWLink';

export default ({ directLink }) => {
  return (
    <Fragment>
      <BWLink directLink={directLink} to="/getting-started">
        Get Started
      </BWLink>
      <BWLink
        directLink={directLink}
        to="/solar-financing-for-business-and-nonprofits">
        Financing & Ownership
      </BWLink>
      <BWLink
        directLink={directLink}
        to="/solar-energy-panel-installation-timeline">
        Installation Timeline
      </BWLink>
      <BWLink directLink={directLink} to="/case-studies/">
        Case Studies
      </BWLink>
      <BWLink directLink={directLink} to="/about-braggawatt">
        About Us
      </BWLink>
    </Fragment>
  );
};
