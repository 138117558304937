/* Slightly modified model for the CurrentUser */
import { types } from 'mobx-state-tree';
import _pick from 'lodash/pick';

export default types
  .model('CurrentUser', {
    _id: types.optional(types.string, ''),
    bio: types.optional(types.string, ''),
    cid: types.maybeNull(types.string),
    company: types.maybeNull(types.string),
    company_name: types.maybeNull(types.string),
    date_joined: types.optional(types.string, ''),
    email: types.maybeNull(types.string),
    is_active: types.optional(types.boolean, true),
    last_login: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    phone_cell: types.maybeNull(types.string),
    phone_desk: types.maybeNull(types.string),
    pid: types.maybeNull(types.string),
    token: types.maybeNull(types.string),
    uid: types.maybeNull(types.string),
    role: types.optional(
      types.union(
        types.literal('superadmin'),
        types.literal('admin'),
        types.literal('integrator'),
        types.literal('superintegrator'),
        types.literal('enterprise'),
        types.literal('credit'),
        types.literal('sales'),
        types.literal('guest')
      ),
      'guest'
    )
  })
  .views(self => ({
    get status() {
      return self.is_active ? 'Active' : 'Inactive';
    },

    get isAuthenticated() {
      return self.role !== 'guest';
    },

    get formattedUser() {
      const keys = ['cid', 'pid', 'uid', 'role', 'name', 'email', 'company'];
      const data = _pick(self, keys);

      return data;
    }
  }));
