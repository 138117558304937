import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import Column from './Column';
import cx from 'classnames';

import './css/row.scss';

export default class Row extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isForm: PropTypes.bool,
    centerAlign: PropTypes.bool,
    minimalPadding: PropTypes.bool,
    extraColumns: PropTypes.bool
  };

  static defaultProps = {
    extraColumns: true
  };

  renderChildren = classNames => {
    const { extraColumns, children } = this.props;
    if (extraColumns && Children.count(children) === 1) {
      let col = 0;
      let md = 0;
      let lg = 0;
      Children.forEach(children, child => {
        const { colSize, sizeMd, sizeLg } = child.props;
        col = 12 - colSize;
        md = 12 - sizeMd;
        lg = 12 - sizeLg;
      });

      return (
        <div className={classNames}>
          <Column colSize={col / 2} sizeMd={md / 2} sizeLg={lg / 2} />
          {children}
          <Column colSize={col / 2} sizeMd={md / 2} sizeLg={lg / 2} />
        </div>
      );
    }
    return <div className={classNames}>{children}</div>;
  };

  render() {
    const {
      className,
      isForm,
      centerAlign,
      minimalPadding,
      marginBottom
    } = this.props;

    const classNames = cx('row', {
      [className]: className,
      '-isForm': isForm,
      '-centerAlign': centerAlign,
      '-minimalPadding': minimalPadding,
      '-marginBottom': marginBottom
    });

    return this.renderChildren(classNames);
  }
}
