import React, { Component } from 'react';
import cx from 'classnames';

export default class Head2 extends Component {
  render() {
    const { align, children, className, cy, ...rest } = this.props;
    const headCx = cx({ [`-${align}`]: align, [className]: className });
    return (
      <h2 className={headCx} data-cy={cy} {...rest}>
        {' '}
        {children}
      </h2>
    );
  }
}
