import React from 'react';
import './css/loader.scss';

export default function Loading({ error, retry, pastDelay }) {
  if (error) {
    return (
      <div>
        Error! <button onClick={retry}>Retry</button>
      </div>
    );
  } else if (pastDelay) {
    return (
      <div className="a-loader">
        <div>
          <div className="a-loader__circle1 a-loader__circle" />
          <div className="a-loader__circle2 a-loader__circle" />
          <div className="a-loader__circle3 a-loader__circle" />
          <div className="a-loader__circle4 a-loader__circle" />
          <div className="a-loader__circle5 a-loader__circle" />
          <div className="a-loader__circle6 a-loader__circle" />
          <div className="a-loader__circle7 a-loader__circle" />
          <div className="a-loader__circle8 a-loader__circle" />
          <div className="a-loader__circle9 a-loader__circle" />
          <div className="a-loader__circle10 a-loader__circle" />
          <div className="a-loader__circle11 a-loader__circle" />
          <div className="a-loader__circle12 a-loader__circle" />
        </div>
      </div>
    );
  }
  return null;
}
