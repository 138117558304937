import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Button from '@bw/library/Button';
import ButtonActionGroup from '@bw/library/ButtonActionGroup';
import PrivacyPolicy from '@bw/modules/App/components/PrivacyPolicy';
import ModalV2 from './ModalV2';
import './privacy-policy-modal.scss';

@inject('Store')
@observer
export default class PrivacyPolicyModal extends Component {
  render() {
    const { Store } = this.props;
    return (
      <ModalV2
        open={Store.App.ui.privacyModal}
        onClose={Store.App.ui.closePrivacyModal}
        center={true}>
        <div className="a-privacyPolicyModal">
          <PrivacyPolicy />
        </div>
        <ButtonActionGroup>
          <Button
            type="primary"
            onClick={Store.App.ui.closePrivacyModal}
            label="Close"
          />
        </ButtonActionGroup>
      </ModalV2>
    );
  }
}
