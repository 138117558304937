export { default as firebase } from './firebase';
export { default as google } from './google';
export { default as api } from './api';
export { default as slack } from './slack';
export { default as segment } from './segment';
export { default as intercom } from './intercom';

export const mapbox =
  'pk.eyJ1IjoiYW5kcmVmb3giLCJhIjoiY2p2c2J2cmswMTNuNDRhbzhpaTVsbG42aCJ9.NQL3KeB4d7nAhQcxF4dzRw';
export const environmentName = 'test';

console.log('Test configs');
