import React from 'react';
import styled from 'styled-components';
import ReadMoreItem from './ReadMoreItem';

const ReadMoreStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  > * {
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      & + * {
        margin-left: 20px;
      }
    }
    @media (min-width: 992px) {
      margin-right: 0;
      max-width: 380px;
    }
  }
`;
export default ({ items }) => {
  return (
    <ReadMoreStyled>
      {items.map((item, index) => (
        <ReadMoreItem key={item.id} item={item} delay={index * 200} />
      ))}
    </ReadMoreStyled>
  );
};
