import React from 'react';
import cx from 'classnames';
import { Parallax } from 'react-scroll-parallax';
import './css/photo-quote.scss';

export default ({
  className,
  avatar,
  children,
  person,
  title,
  offsetYMax,
  offsetYMin
}) => {
  const divCx = cx('m-photoQuote', {
    [className]: true
  });
  return (
    <Parallax y={[offsetYMin, offsetYMax]} slowerScrollRate={true} tag="div">
      <div className={divCx} data-aos="fade-up">
        <div className="m-photoQuote__inner">
          <div className="m-photoQuote__quote">
            {children}
            <div className="m-photoQuote__photoBox">
              <div className="m-photoQuote__avatar">
                <img alt="avatar" src={avatar} />
              </div>
              <div className="m-photoQuote__signature">
                {person} <br />
                {title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
};
