import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LandingPage from '@bw/modules/Marketing/components/LandingPage';
import Archway from '@bw/modules/CaseStudies/components/Archway';
import SouthernJersey from '@bw/modules/CaseStudies/components/SouthernJersey';
import Starcross from '@bw/modules/CaseStudies/components/Starcross';
import Wildwood from '@bw/modules/CaseStudies/components/Wildwood';
import CaseStudyList from '@bw/modules/CaseStudies/components/CaseStudyList';
import Loadable from 'react-loadable';
import Loader2 from '@bw/library/Loader2';
import PageNotFound from '@bw/components/PageNotFound';

const Contact = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'contact' */ '@bw/modules/Marketing/components/about/Contact1'
    ),
  loading: () => <Loader2 />
});

const PrivacyPolicy = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'privacyPolicy' */ '@bw/modules/App/components/PrivacyPolicy'
    ),
  loading: () => <Loader2 />
});

const OwnershipOptions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ownershipOptions' */ '@bw/modules/Marketing/components/ownershipOptions/OwnershipOptions'
    ),
  loading: () => <Loader2 />
});

// const CaseStudiesRoutes = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: 'caseStudiesRoutes' */ '@bw/modules/CaseStudies/Routes'),
//   loading: () => <Loader2 />
// });

const WhySolar = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "whySolar" */ '@bw/modules/Marketing/components/solar/WhySolar'
    ),
  loading: () => <Loader2 />
});

const HowSolarWorks = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'howSolarWorks' */ '@bw/modules/Marketing/components/solar/HowSolarWorks'
    ),
  loading: () => <Loader2 />
});

const SolarPanels = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'solarPanels' */ '@bw/modules/Marketing/components/solar/SolarPanels'
    ),
  loading: () => <Loader2 />
});

const FinancialBenefits = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'financialBenefits' */ '@bw/modules/Marketing/components/solar/FinancialBenefits'
    ),
  loading: () => <Loader2 />
});

const CompanyOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'companyOverview' */ '@bw/modules/Marketing/components/about/CompanyOverview'
    ),
  loading: () => <Loader2 />
});

const SolarFinancingOwnershipOptions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'whoIsItFor' */ '@bw/modules/Marketing/components/about/SolarFinancingOwnershipOptions'
    ),
  loading: () => <Loader2 />
});

const OurProcess = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ourProcess' */ '@bw/modules/Marketing/components/about/OurProcess'
    ),
  loading: () => <Loader2 />
});

const InstallationTimeline = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ourProcess' */ '@bw/modules/Marketing/components/about/InstallationTimeline'
    ),
  loading: () => <Loader2 />
});

const ForBusiness = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'forBusiness' */ '@bw/modules/Marketing/components/ForBusiness'
    ),
  loading: () => <Loader2 />
});

const ForNonprofit = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'forNonprofit' */ '@bw/modules/Marketing/components/ForNonprofit'
    ),
  loading: () => <Loader2 />
});

const FormIntegrator = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'formIntegrator' */ '@bw/modules/Marketing/components/integrator/FormIntegrator'
    ),
  loading: () => <Loader2 />
});

// const SouthernJersey = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: 'southernJersey' */ '@bw/modules/CaseStudies/components/SouthernJersey'),
//   loading: () => <Loader2 />
// });
// const Wildwood = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: 'southernJersey' */ '@bw/modules/CaseStudies/components/Wildwood'),
//   loading: () => <Loader2 />
// });

// const CaseStudyList = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: 'southernJersey' */ '@bw/modules/CaseStudies/components/CaseStudyList'),
//   loading: () => <Loader2 />
// });

// const LandingPage = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "landingPage" */ '@bw/modules/Marketing/components/LandingPage'),
//   loading: () => <Loader2 />
// });

const Routes = () => (
  <Switch>
    <Route path="/" exact={true} component={LandingPage} />
    <Route path="/privacy-policy" exact={true} component={PrivacyPolicy} />

    <Route path="/contact" exact={true} component={Contact} />
    <Route
      exact={true}
      path="/ownership-options"
      component={OwnershipOptions}
    />

    <Route exact={true} path="/solar-for-business" component={ForBusiness} />
    <Route exact={true} path="/solar-for-nonprofit" component={ForNonprofit} />
    <Route exact={true} path="/small-business-solar" component={WhySolar} />
    <Route
      exact={true}
      path="/how-solar-energy-works"
      component={HowSolarWorks}
    />
    <Route
      exact={true}
      path="/solar-panels-and-systems"
      component={SolarPanels}
    />

    <Route
      exact={true}
      path="/solar-financial-benefits"
      component={FinancialBenefits}
    />

    <Route exact={true} path="/about-braggawatt" component={CompanyOverview} />

    <Route exact={true} path="/getting-started" component={OurProcess} />

    <Route
      exact={true}
      path="/solar-energy-panel-installation-timeline"
      component={InstallationTimeline}
    />

    <Route
      exact={true}
      path="/solar-financing-for-business-and-nonprofits"
      component={SolarFinancingOwnershipOptions}
    />

    <Route
      exact={true}
      path="/solar-integrator-financing-platform"
      component={FormIntegrator}
    />

    {/* Case Studies */}
    <Route exact={true} path="/case-studies" component={CaseStudyList} />
    <Route exact={true} path="/case-studies-archway" component={Archway} />
    <Route
      exact={true}
      path="/case-studies-southern-jersey"
      component={SouthernJersey}
    />
    <Route exact={true} path="/case-studies-starcross" component={Starcross} />
    <Route exact={true} path="/case-studies-wildwood" component={Wildwood} />
    <Route path="/404" component={PageNotFound} />
    <Redirect to="/404" />
  </Switch>
);

export default Routes;
