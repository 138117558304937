import React, { PureComponent } from 'react';
import Modal from 'react-responsive-modal';
import './modalv2.scss';

export default class ModalV2 extends PureComponent {
  static defaultProps = {
    padding: true,
    size: 'lg'
  };

  render() {
    const { open, children, onClose, padding, size } = this.props;

    return (
      <Modal
        open={open}
        classNames={{
          modal: `a-modalV2 -${size} ${padding ? '-padding' : ''}`
        }}
        center={true}
        onClose={onClose}>
        {children}
      </Modal>
    );
  }
}
