import React from 'react';
import { Link } from 'react-router-dom';
import StyledCaseStudyListItem from './StyledCaseStudyListItem';

export default function CaseStudyGalleryItem({ item, push }) {
  const link = item.comingSoon ? '#' : item.path;

  return (
    <StyledCaseStudyListItem item={item}>
      <div className="image" onClick={() => push(link)} />
      <div className="bottom">
        <Link to={link}>
          <div className="title">{item.title}</div>
          <div className="location">{item.location}</div>
        </Link>
      </div>
    </StyledCaseStudyListItem>
  );
}
