import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Button from './Button';
import ButtonActionGroup from './ButtonActionGroup';
import Head3 from './headings/Head3';
import FormTitle from './headings/FormTitle';
import InputSelect from './forms/InputSelect';
import InputTextArea from './forms/InputTextArea';
import InputText from './forms/InputText';
import ModalV2 from './modal/ModalV2';
import airplaneImg from './images/paper-plane.svg';
import './css/email-support-modal.scss';

@inject('Store')
@withRouter
@observer
export default class EmailSupportModal extends Component {
  render() {
    const { Store, location } = this.props;

    const reasons = [
      { id: 'BUGS', label: 'I found an issue with the platform' },
      {
        id: 'PROBLEM_WITH_PLATFORM',
        label: 'Having problems using the platform'
      },
      { id: 'SALES_MARKETING', label: 'Sales and Marketing' },
      { id: 'GENERAL', label: 'General question' }
    ];
    const { pathname } = location;
    return (
      <div className="a-emailSupportModal">
        <ModalV2
          open={Store.App.email.modal}
          onClose={Store.App.email.cancelRequest}>
          {Store.App.email.modalType === 'FORM' ? (
            <Fragment>
              <Head3 label="Contact Us" />
              <FormTitle label="Please let us know what we can do to help you." />
              <div className="a-emailSupportModal__fields">
                <InputSelect
                  label="What is your reason for contacting us?"
                  options={reasons}
                  formField={Store.App.form.$('email.reason')}
                />
                <InputText
                  label="Full name"
                  formField={Store.App.form.$('email.name')}
                />
                <InputText
                  placeholder="yourname@email.com"
                  label="Email Address"
                  formField={Store.App.form.$('email.address')}
                />
                <InputTextArea
                  label="Message"
                  formField={Store.App.form.$('email.message')}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Head3 label="Contact Us" />
              <FormTitle label="Please let us know what we can do to help you." />
              <div className="a-emailSupportModal__airplane">
                <img alt="Thanks!" src={airplaneImg} />
              </div>
            </Fragment>
          )}
          <ButtonActionGroup>
            {Store.App.email.modalType === 'FORM' ? (
              <Fragment>
                <Button
                  type="simple"
                  label="Cancel"
                  onClick={Store.App.email.cancelRequest}
                />
                <Button
                  type="primary"
                  label="Submit"
                  onClick={() => Store.App.email.submitEmail({}, pathname)}
                />
              </Fragment>
            ) : (
              <Button
                type="modal"
                label="OK"
                onClick={Store.App.email.cancelRequest}
              />
            )}
          </ButtonActionGroup>
        </ModalV2>
      </div>
    );
  }
}
