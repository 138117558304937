export function getAllParameterValues(url) {
  const params = new URLSearchParams(url);
  let paramObj = {};
  for (var value of params.keys()) {
    paramObj[value] = params.get(value);
  }

  return paramObj;
}

/**
 * Returns the value of a passed in query string key
 * @param {String} name
 * @param {String} url
 */
export default function getParameterByName(name, url) {
  // if (!url) url = window.location.href;
  /* eslint-disable no-useless-escape */
  name = name.replace(/[\[\]]/g, '\\$&');
  /* eslint-disable no-useless-escape */
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
