/* NOTE Navbar used for Marketing Site Only! */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import cx from 'classnames';
import Button from '../Button';
import DesktopAbout from './DesktopAbout';
import HamburgerMenu from './HamburgerMenu';
import MobileMenuDropdown from './MobileMenuDropdown';
import { api } from '@bw/config';
import './header.scss';

@inject('Store')
@withRouter
@observer
export default class NavBar extends Component {
  state = {
    active: false,
    userDropdown: false
  };

  constructor(props) {
    super(props);
    this.dropdown = React.createRef();
    this.mobileDropdown = React.createRef();
  }

  componentDidMount() {}

  toggleMenu = e => {
    e.preventDefault();
    this.setState({
      active: !this.state.active
    });
  };

  closeMenu = () => {
    this.setState({
      active: false
    });
    this.closeUserDropdown();
  };

  handleLogin = () => {
    window.location = `${api.appURL}/login`;
  };

  handleReturningUser = () => {
    const { Store } = this.props;
    Store.App.redirectUser();
  };

  handleRoute = route => {
    const { history } = this.props;
    history.push(route);
  };

  toggleDropdown = () => {
    this.setState({
      userDropdown: !this.state.userDropdown,
      mobileDropdown: !this.state.mobileDropdown
    });
  };

  closeUserDropdown = () => {
    if (this.dropdown) {
      this.dropdown.hide();
    }

    if (this.mobileDropdown) {
      this.mobileDropdown.hide();
    }
  };

  isInAuthenticatedPath = () => {
    const { location } = this.props;
    const { pathname } = location;
    const routesRegEx = new RegExp(
      /credit|enterprises\/projects|projects|users|credits|companies|profile/
    );
    return routesRegEx.test(pathname);
  };

  render() {
    const { Store } = this.props;
    const navClassNames = cx('m-navBar', { active: this.state.active });
    const isInAuthPath = this.isInAuthenticatedPath();

    return (
      <header className="o-header">
        <nav className={navClassNames}>
          <div className="m-navBar__group">
            <div className="m-navBar__leftElement">
              <div className="m-navBar__logo">
                <Link to="/" aria-label="Braggawatt Home">
                  <span>Home</span>
                </Link>
              </div>
            </div>

            <div className="m-navBar__rightMenu">
              {/* Dropdown Navigations based on browser type  */}
              {!isInAuthPath && (
                <DesktopAbout
                  handleRoute={this.handleRoute}
                  directLink={false}
                />
              )}

              {/* Desktop Icons  */}

              {!isInAuthPath && (
                <Button
                  className="m-navBar__calculate"
                  label="See Savings"
                  type="primary"
                  onClick={Store.App.ui.openZipEntry}
                />
              )}

              {Store.App.currentUser.isAuthenticated ? (
                <Button
                  className="m-navBar__login"
                  label="Return to App"
                  type="secondary"
                  onClick={this.handleReturningUser}
                />
              ) : (
                <Button
                  className="m-navBar__login"
                  label="Login"
                  type="secondary"
                  onClick={this.handleLogin}
                />
              )}

              <HamburgerMenu />
            </div>
          </div>
        </nav>
        <MobileMenuDropdown
          handleReturningUser={this.handleReturningUser}
          isInAuthPath={isInAuthPath}
          isAuthenticated={Store.App.currentUser.isAuthenticated}
        />
      </header>
    );
  }
}
