import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import WithFooter from '@bw/library/footer/WithFooter';
import ZipCodeEntry from '@bw/modules/Marketing/components/ZipCodeEntry';
import BlockStats from './BlockStats';
import Gallery from './ArchwayImageGallery';
import PhotoQuote from './PhotoQuote';
import Quote from './Quote';
import StyledBlockStats from './StyledBlockStats';
import StyledBuildingGrid from './StyledBuildingGrid';
import StyledHeader from './StyledHeader';
import StyledHero from './StyledHero';
import StyledLowerGrid from './StyledLowerGrid';
import StyledQuoteWrapper from './StyledQuoteWrapper';
import StyledSignificantSavings from './StyledSignificantSavings';
import StyledCaseStudy from './StyledCaseStudy';

//!!!! Update these !!!!
import heroDesktop from '../images/wildwood/hero-desktop.jpg';
import buildingDesktop from '../images/wildwood/building-desktop.jpg';
import guysPlanningSm from '../images/wildwood/group.jpg';
import daveAvatar from '../images/wildwood/dave.png';
import jrAvatar from '../images/wildwood/jr.png';

export default class Archway extends Component {
  render() {
    return (
      <WithFooter background={true}>
        <StyledCaseStudy>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="Solar financing for business is now easier than ever, with expertise from Braggawatt. Take advantage of the benefits of solar for your business or Nonprofit today. Learn more."
            />
            <title>
              Benefits of Solar Energy & Financing Solar for Business
            </title>
          </Helmet>
          <StyledHero>
            <picture>
              <img src={heroDesktop} alt="Solar panels" />
            </picture>
            <div className="gradient" />
          </StyledHero>
          <StyledHeader data-aos="fade-right" data-aos-delay={200}>
            <div className="inner">
              Archway Programs
              <div className="loc">Atco, NJ</div>
            </div>
          </StyledHeader>
          <Quote>
            We were looking for a consistent partner we could trust. We wanted
            to keep things moving, and we knew Braggawatt could get it done.
          </Quote>
          <StyledBuildingGrid>
            <div className="building" data-aos="zoom-out">
              <picture>
                {/*   <source media="(max-width: 576px)" srcSet={buildingMobile} />
                <source media="(max-width: 768px)" srcSet={buildingTablet} /> */}
                <img src={buildingDesktop} alt="Solar building project" />
              </picture>
            </div>

            <div className="descWrapper">
              <div className="desc" data-aos="fade-right">
                <p>
                  <a
                    href="https://wildwoodcrest.org/firedepartment.php"
                    target="_blank">
                    Wildwood Crest Volunteer Fire Department
                  </a>{' '}
                  (WCVFD) has two goals:
                </p>
                <ol>
                  <li>Positively impact their New Jersey community</li>
                  <li>Reduce operating costs to save taxpayer money</li>
                </ol>
                <p>
                  With goals like these, installing a solar energy system seemed
                  like a no-brainer for this nonprofit. However, the department
                  struggled to find a partner willing to invest in their small
                  municipal project.
                </p>
                <p>
                  But then a trusted, local installation partner,{' '}
                  <a href="https://www.castleenergyllc.com/" target="_blank">
                    Castle Energy
                  </a>
                  , suggested Braggawatt. After a simple conversation and
                  project estimate, the department found the Power Purchase
                  Agreement (PPA) terms they’d been searching for. With no
                  upfront investment, they were able to quickly go solar to
                  reduce their energy costs.
                </p>
              </div>
            </div>
            <StyledQuoteWrapper className="-ronald">
              <PhotoQuote
                offsetYMax="-100px"
                offsetYMin="100px"
                avatar={daveAvatar}
                className="-ronald"
                person="David Thompson"
                title="Commissioner of Public Affairs & Public Safety, Wildwood Crest, NJ">
                "We looked for a while, and these were just the right terms.
                Braggawatt had exactly what we wanted and we said ‘we’re going
                to stick with this.’ We wanted to move quickly, and they could."
              </PhotoQuote>
            </StyledQuoteWrapper>
          </StyledBuildingGrid>
          <StyledSignificantSavings data-aos="fade-up">
            <div className="header">Savings for the City</div>
            <div className="text">
              WCVFD is an all-volunteer department serving a community of 3,500
              year-round residents (and as many as 80,000 seasonal visitors!).
              As representatives and residents of the city, the department’s 40
              active members know that lower station operating costs mean more
              tax dollars left for community improvement, local events, and
              perhaps even larger paychecks.
            </div>
          </StyledSignificantSavings>
          <StyledBlockStats>
            <div className="title">WCVFD Projections</div>
            <div className="wrapper">
              <BlockStats value="75 kW" label="System Size" delay={200} />
              <BlockStats
                value="20%"
                label="Reduction in Electricity Costs (per kWh)"
                delay={400}
              />
              <BlockStats
                value="$70K"
                label="Estimated Lifetime Savings"
                delay={600}
              />
              <BlockStats
                value="942"
                label="Community Trees Replaced per Year"
                delay={900}
              />
            </div>
          </StyledBlockStats>
          <StyledLowerGrid>
            <div className="inner">
              <div className="headerGroup">
                <div className="header" data-aos="fade-up">
                  Community
                </div>
                <div className="text" data-aos="fade-up">
                  <p>
                    As a community leader, WCVFD prioritized local businesses.
                    They chose to work with{' '}
                    <a href="https://www.castleenergyllc.com/" target="_blank">
                      Castle Energy
                    </a>
                    , a local solar installer and change agent committed to
                    serving customers by securing the best, most reliable solar
                    contracting terms to fit their client’s individual needs. As
                    a member of Braggawatt’s trusted installer network, Castle
                    knew of our ongoing commitment to fund solar for nonprofits,
                    and that we offer the most reliable nonprofit solar
                    contracting terms.
                    <a href="https://www.castleenergyllc.com/" target="_blank">
                      Castle Energy
                    </a>{' '}
                    relied on our{' '}
                    <Link to="/braggawatt-solar-process">online process</Link>{' '}
                    and dependable underwriting to offer WCVFD an opportunity no
                    one else could match — secure financing and a turnkey
                    partner who would help bring their solar vision a reality,
                    from start to finish (including fair monetization of
                    regional solar benefits, like{' '}
                    <a
                      href="https://www.energy.gov/savings/dsire-page"
                      target="_blank">
                      SRECs
                    </a>
                    , etc).
                  </p>
                </div>
              </div>
            </div>
            <div className="guysPlanning">
              <div
                className="guysPlanning__inner"
                style={{ backgroundImage: `url(${guysPlanningSm})` }}
              />
            </div>
            <StyledQuoteWrapper className="-gary">
              <PhotoQuote
                offsetYMax="100px"
                offsetYMin="200px"
                avatar={jrAvatar}
                className="-gary"
                person="JR Castle"
                title="Owner, Castle Energy, LLC">
                "Partnering with Braggawatt is a win-win-win. It’s quick, easy,
                and makes a lot of different people happy. I don’t see a
                downside to it at all. It’s a no-brainer for us to work with
                them."
              </PhotoQuote>
            </StyledQuoteWrapper>
          </StyledLowerGrid>
          <Gallery />
          <ZipCodeEntry minimal={true} />
        </StyledCaseStudy>
      </WithFooter>
    );
  }
}
