import React from 'react';
import { Transition as AlertTransition } from 'react-transition-group';

const duration = 200;

const defaultStyle = {
  fade: {
    transition: `opacity ${duration}ms ease`,
    opacity: 0
  },
  scale: {
    transform: 'scale(1)',
    transition: `all ${duration}ms ease-in-out`
  },
  animateDown: {
    transform: 'translateY(0)',
    transition: `all ${duration}ms ease`
  }
};

const transitionStyles = {
  fade: {
    entering: { opacity: 0 },
    entered: { opacity: 1 }
  },
  scale: {
    entering: { transform: 'scale(0)' },
    entered: { transform: 'scale(1)' },
    exiting: { transform: 'scale(0)' },
    exited: { transform: 'scale(1)' }
  },
  animateDown: {
    entering: {
      opacity: 0,
      transform: 'translateY(-60px)'
    },
    entered: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    exiting: {
      opacity: 0,
      transform: 'translateY(-60px)'
    },
    exited: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }
};

const Transtion = ({ children, type, ...props }) => (
  <AlertTransition {...props} timeout={duration}>
    {state => (
      <div
        style={{
          ...defaultStyle[type],
          ...transitionStyles[type][state]
        }}>
        {children}
      </div>
    )}
  </AlertTransition>
);

export default Transtion;
