import styled from 'styled-components';

export default styled.div`
  max-width: 1240px;
  margin: 20px auto 32px;
  padding: 0 20px;

  @media (min-width: ${({ theme }) => theme['res-lg']}) {
    margin: 80px auto 32px;
  }

  .infoCol {
    padding: 40px 20px;

    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      padding: 0;
    }

    + .infoCol {
      border-top: 1px solid ${({ theme }) => theme['color-gray2']};
      margin-top: 32px;
      padding-top: 60px;
      @media (min-width: ${({ theme }) => theme['res-lg']}) {
        padding-top: 0;
        margin-top: 0;
        border-top: none;
        border-left: 1px solid ${({ theme }) => theme['color-gray2']};
      }
    }
  }

  .infoHead {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    align-items: center;
    margin: 0 auto 28px;
    justify-content: center;
    text-align: center;

    @media (min-width: ${({ theme }) => theme['res-lg']}) { {
      max-width: initial;
    }

    img {
      margin-bottom: 24px;
      max-height: 94px;
    }
    span {
      line-height: 1;
    }

    h2 {
      @media (min-width: ${({ theme }) => theme['res-md']}) {
        max-width: 220px;
      }
    }
  }
`;
