import React from 'react';

import Head1 from '@bw/library/headings/Head1';
import Head2 from '@bw/library/headings/Head2';
import WithFooter from '@bw/library/footer/WithFooter';
import StyledCaseStudyList from './StyledCaseStudyList';
import CaseStudyListItem from './CaseStudyListItem';
import CaseStudyMap from './CaseStudyMap';
import sjfmcThumb from '../images/sjfmc-thumb.jpg';
import wildwoodThumb from '../images/wildwood-thumb.jpg';
import starcrossThumb from '../images/starcross-thumb.jpg';
import archwayThumb from '../images/archway-thumb.jpg';

const caseStudies = [
  {
    id: 'wildwood',
    title: 'Wildwood Fire Department',
    location: 'Wildwood, New Jersey',
    path: '/case-studies-wildwood',
    thumb: wildwoodThumb
  },
  {
    id: 'southern-jersey',
    title: 'Southern Jersey Family Medical Centers',
    location: 'Burlington, New Jersey',
    path: '/case-studies-southern-jersey',
    thumb: sjfmcThumb
  },
  {
    id: 'starcross',
    title: 'Star Cross Volunteer Fire Department',
    location: 'Williamstown, New Jersey',
    path: '/case-studies-starcross',
    thumb: starcrossThumb
    //comingSoon: true
  }
  /*
  {
    id: 'archway',
    title: 'Archway Programs',
    location: 'Atco, New Jersey',
    path: '/case-studies-archway',
    thumb: archwayThumb
    //comingSoon: true
  }
  */
];

export default function CaseStudies(props) {
  return (
    <WithFooter background={true}>
      <StyledCaseStudyList>
        <Head1 align="center">Case Studies</Head1>
        <div className="list">
          {caseStudies.map(item => (
            <CaseStudyListItem
              key={item.id}
              item={item}
              push={props.history.push}
            />
          ))}
        </div>
        <div className="map">
          <Head2 align="center">
            Businesses & nonprofits across the country calculate savings and go
            solar with Braggawatt!
          </Head2>
          <div className="mapHolder">
            <CaseStudyMap />
          </div>
        </div>
      </StyledCaseStudyList>
    </WithFooter>
  );
}
