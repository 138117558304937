import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import WithFooter from '@bw/library/footer/WithFooter';
import ZipCodeEntry from '@bw/modules/Marketing/components/ZipCodeEntry';
import BlockStats from './BlockStats';
import Gallery from './StarcrossImageGallery';
import PhotoQuote from './PhotoQuote';
import Quote from './Quote';
import StyledBlockStats from './StyledBlockStats';
import StyledBuildingGrid from './StyledBuildingGrid';
import StyledHeader from './StyledHeader';
import StyledHero from './StyledHero';
import StyledLowerGrid from './StyledLowerGrid';
import StyledQuoteWrapper from './StyledQuoteWrapper';
import StyledSignificantSavings from './StyledSignificantSavings';
import StyledCaseStudy from './StyledCaseStudy';

//import heroDesktop from '../images/wildwood/hero-desktop.jpg';
import heroDesktop from '../../Marketing/images/non-profit-bg-desktop.jpg'; //'../images/starcross/starcross-main2.jpg';
import hero2Desktop from '../images/starcross/starcross-hero2-image.jpg';
import secondImage from '../images/starcross/starcross-image-7.jpg';
import Avatar from '../images/starcross/starcross-badge.jpg';

export default class StarCross extends Component {
  render() {
    return (
      <WithFooter background={true}>
        <StyledCaseStudy>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="Solar financing for business is now easier than ever, with expertise from Braggawatt. Take advantage of the benefits of solar for your business or Nonprofit today. Learn more."
            />
            <title>
              Benefits of Solar Energy & Financing Solar for Business
            </title>
          </Helmet>
          <StyledHero>
            <picture>
              <img src={heroDesktop} alt="Solar panels" />
            </picture>
            <div className="gradient" />
          </StyledHero>
          <StyledHeader data-aos="fade-right" data-aos-delay={200}>
            <div className="inner">
              Star Cross Volunteer Fire Department
              <div className="loc">Williamstown, NJ</div>
            </div>
          </StyledHeader>
          <Quote>
            People don't care about smaller solar projects like this. They
            aren't interested or think they aren't worth the time. Braggawatt
            understood why this project was important to us and were willing to
            make it work.
          </Quote>
          <StyledBuildingGrid>
            <div className="building" data-aos="zoom-out">
              <picture>
                {/*   <source media="(max-width: 576px)" srcSet={buildingMobile} />
                <source media="(max-width: 768px)" srcSet={buildingTablet} /> */}
                <img src={hero2Desktop} alt="Solar building project" />
              </picture>
            </div>

            <div className="descWrapper">
              <div className="desc" data-aos="fade-right">
                <p>
                  <a
                    href="http://www.starcrossfire.com/home.html"
                    target="_blank">
                    Star Cross Volunteer Fire Department
                  </a>{' '}
                  (SCVFD) is a small outfit based in Williamstown, New Jersey.
                  Because of their size and budget, they were finding it hard to
                  go solar, and became frustrated after several attempts to
                  finance a system fell through.
                </p>
                <p>
                  But at Braggawatt, our mission is to help small nonprofits
                  take advantage of the benefits of solar. We connected with
                  SCVFD, and took the time to understand their unique needs.
                </p>
                <p>
                  As a result, we funded 100% of the Star Cross solar project.
                  SCVFD paid no upfront capital costs, and the station’s Power
                  Purchase Agreement (PPA) not only allows them to take
                  advantage of the solar tax credits typically denied to
                  nonprofits, it will also help them hedge against rising
                  electricity prices.
                </p>
              </div>
            </div>
            <StyledQuoteWrapper className="-ronald">
              <PhotoQuote
                offsetYMax="10px"
                offsetYMin="280px"
                avatar={Avatar}
                className="-ronald"
                person="David Deegan"
                title="SCVFD Fire Chief">
                "We had a couple people say they would finance the project, but
                then they got alligator arms. Braggawatt was the only one to
                commit and get the job done. And they got it done quickly."
              </PhotoQuote>
            </StyledQuoteWrapper>
          </StyledBuildingGrid>
          <StyledSignificantSavings data-aos="fade-up">
            <div className="header">
              Savings that Support Responsible Budgets
            </div>
            <div className="text">
              When you’re running a volunteer fire department, every dollar
              counts. Over the life of the solar system, SCVD will save over
              $70,000. That’s money that will go right back into the community,
              because Braggawatt paid for the entire system. Plus, it never
              hurts to help the environment — the station’s solar system will
              help offset fuel and emissions produced by their trucks!
            </div>
          </StyledSignificantSavings>
          <StyledBlockStats>
            <div className="title">SCVFD Projections</div>
            <div className="wrapper">
              <BlockStats value="54 kW" label="System Size" delay={200} />
              <BlockStats value="$0.03" label="Savings per kWh" delay={400} />
              <BlockStats
                value="97%"
                label="Annual offset of utility reliance"
                delay={600}
              />
              <BlockStats
                value="26 tons"
                label="CO2 kept out of the atmosphere"
                delay={900}
              />
            </div>
          </StyledBlockStats>
          <StyledLowerGrid>
            <div className="inner">
              <div className="headerGroup">
                <div className="header" data-aos="fade-up">
                  Big Tax Breaks for a Small Nonprofit
                </div>
                <div className="text" data-aos="fade-up">
                  <p>
                    As a small, volunteer fire station, Star Cross wondered
                    whether they could take advantage of federal solar tax
                    credits. Most tax credits only apply to for-profit
                    businesses. But Braggawatt had an answer.
                  </p>
                  <p>
                    Since SCVFD’s solar system is owned by a for-profit
                    business, the owner received the applicable tax credit, and
                    can pass the savings on to SCVFD through their PPA. In other
                    words, because a for-profit owner is able to monetize the
                    credits, they offer reduced electricity rates. Currently,
                    SCVFD pays only $0.11/kWh instead of the usual $0.14/kWh
                    utility rate. That’s 20% off the station’s electricity bill.
                  </p>
                </div>
              </div>
            </div>
            <div className="guysPlanning">
              <div
                className="guysPlanning__inner"
                style={{ backgroundImage: `url(${secondImage})` }}
              />
            </div>
            <StyledQuoteWrapper className="-gary">
              <PhotoQuote
                offsetYMax="200px"
                offsetYMin="320px"
                avatar={Avatar}
                className="-gary"
                person="Robert Skala"
                title="SCVFD Treasurer">
                "A real upside of working with Braggawatt is the relationship we
                built. I feel comfortable calling them any time. They are always
                willing to spend time working through what we need, and it's
                great to have found a long-term partner."
              </PhotoQuote>
            </StyledQuoteWrapper>
          </StyledLowerGrid>
          <div style={{ paddingTop: '50px' }}></div>
          <Gallery />
          <ZipCodeEntry minimal={true} />
        </StyledCaseStudy>
      </WithFooter>
    );
  }
}
