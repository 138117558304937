import styled from 'styled-components';

export default styled.div`
  display: grid;
  margin-top: 40px;
  grid-template-columns: auto;
  grid-template-areas:
    'lowerGridDesc'
    'guysPlanning'
    'quoteGary';

  @media (min-width: ${({ theme }) => theme['res-md']}) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      'lowerGridDesc guysPlanning'
      'quoteGary .';
    margin-bottom: 32px;
  }

  @media (min-width: ${({ theme }) => theme['res-lg']}) {
    margin-top: 92px;
    grid-template-columns: 1fr 1fr;
  }

  .inner {
    padding: 32px 15px 12px;
    grid-area: lowerGridDesc;
    background: ${({ theme }) => theme['color-gray1']};

    @media (min-width: ${({ theme }) => theme['res-md']}) {
      padding: 32px 20px 12px;
    }
  }

  .headerGroup {
    @media (min-width: ${({ theme }) => theme['res-md']}) {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }

    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      max-width: 433px;
      margin-top: 56px;
      margin-right: 100px;
    }

    .header {
      font-size: 24px;
      font-weight: bold;
      color: ${({ theme }) => theme['color-gray5']};
      margin-bottom: 12px;

      @media (min-width: ${({ theme }) => theme['res-lg']}) {
        font-size: 30px;
      }
    }
  }
  .guysPlanning {
    grid-area: guysPlanning;
    background: ${({ theme }) => theme['color-gray1']};
    overflow: hidden;

    @media (min-width: ${({ theme }) => theme['res-md']}) {
      min-width: 350px;
      height: 693px;
    }

    .guysPlanning__inner {
      height: 348px;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: ${({ theme }) => theme['res-md']}) {
        height: 693px;
        max-width: 369px;
        background-position: 85% center;
      }

      @media (min-width: ${({ theme }) => theme['res-md']}) {
        height: 748x;
        max-width: 900px;
        background-position: 85% center;
      }
    }
  }
`;
