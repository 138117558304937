import styled from 'styled-components';

export default styled.div`
  padding: 32px 15px 12px;
  max-width: 762px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme['res-md']}) {
    max-width: 570px;
    text-align: center;
  }

  @media (min-width: ${({ theme }) => theme['res-lg']}) {
    max-width: 720px;
  }

  .header {
    font-weight: 900;
    font-size: 24px;
    color: $color-gray5;
    margin-bottom: 12px;

    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      font-size: 30px;
      margin-bottom: 3px;
    }
  }

  .text {
    color: ${({ theme }) => theme['color-gray5']};
  }
`;
