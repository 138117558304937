import React, { Component } from 'react';
import { lazyload } from 'react-lazyload';

@lazyload({
  height: 200,
  once: true,
  offset: 100
})
export default class Lazy extends Component {
  render() {
    const { alt, src } = this.props;
    return <img src={src} alt={alt} />;
  }
}
