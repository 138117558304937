import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default styled(Link)`
  cursor: pointer;
  align-items: center;
  border-radius: 3px;
  background: ${props => props.theme['color-primary']};
  border: 1px solid transparent;
  color: ${props => props.theme['color-white']};
  display: flex;
  font-size: 14px;
  font-weight: 900;
  justify-content: center;
  min-height: 50px;
  min-width: 100px;
  outline: none;
  padding: 0 15px;
  text-align: center;
  transition: 0.2s;
  max-width: 460px;

  &:hover {
    color: ${props => props.theme['color-white']};
  }
`;
