import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { toLaxTitleCase } from 'titlecase';
import styled from 'styled-components';
import Lazy from './Lazy';
import arrowIcon from './containers/images/arrow-icon.svg';

const ParentStyled = styled(Link)`
  text-decoration: none;
  display: flex;
  width: 100%;
`;

const LinkStyled = styled.div`
  text-decoration: underline;
  margin-top: auto;
  display: block;
  position: relative;
  padding: 0 15px 20px;
  &::after {
    content: '';
    background: url(${arrowIcon}) no-repeat;
    position: absolute;
    top: 10px;
    transform: rotate(180deg);
    width: 30px;
    height: 10px;
  }

  @media (min-width: 768px) {
    padding: 0 24px 20px;
  }
`;

const Item = styled.div`
  cursor: pointer;
  background: white;
  display: flex;
  text-decoration: none !important;
  flex-direction: column;
  border: 1px solid ${props => props.theme['color-gray2']};
  box-shadow: 0 5px 22px 0 rgba(222, 236, 248, 0.81);
  margin-bottom: 16px;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 350px;
    margin-bottom: 0;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    padding-left: 0;
  }

  .header {
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme['color-gray5']};
    padding: 16px 15px;

    @media (min-width: 768px) {
      padding: 24px 24px 12px;
    }
  }

  .text {
    font-size: 16px;
    font-weight: normal;
    color: ${props => props.theme['color-gray4']};
    padding: 0 15px 20px;

    @media (min-width: 768px) {
      padding: 0 24px 20px;
    }
  }
`;

export default class ReadMoreItem extends Component {
  render() {
    const { item, delay } = this.props;
    return (
      <ParentStyled to={item.link} data-aos="fade-up" data-aos-delay={delay}>
        <Item>
          <Lazy src={item.src} />
          <div className="header">{toLaxTitleCase(item.header)}</div>
          <div className="text">
            {item.text}
            <br />
          </div>
          <LinkStyled>{item.readMoreLabel}</LinkStyled>
        </Item>
      </ParentStyled>
    );
  }
}
