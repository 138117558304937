import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'building'
    'description'
    'quoteRonald';

  @media (min-width: ${({ theme }) => theme['res-md']}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'building building'
      'description quoteRonald';
    margin-bottom: 32px;
  }

  @media (min-width: ${({ theme }) => theme['res-lg']}) {
    margin-bottom: 57px;
  }

  .building {
    grid-area: building;
    max-height: 240px;
    max-width: 1155px;
    overflow: hidden;
    justify-self: center;
    position: relative;

    @media (min-width: ${({ theme }) => theme['res-md']}) {
      max-height: 739px;
      margin-bottom: -60px;
      margin-top: -44px;
    }

    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      max-width: 1240px;
    }

    img {
      @media (min-width: ${({ theme }) => theme['res-md']}) {
        /* padding: 39px; */
        border: 1px solid ${({ theme }) => theme['color-gray2']};
      }
    }
  }

  .descWrapper {
    background: ${({ theme }) => theme['color-gray1']};
    padding: 32px 15px;
    grid-area: description;
    color: ${({ theme }) => theme['color-gray5']};

    @media (min-width: ${({ theme }) => theme['res-md']}) {
      padding: 60px 50px 100px 39px;
    }

    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .desc {
      font-size: 18px;
      max-width: 470px;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: ${({ theme }) => theme['res-lg']}) {
        margin-right: 60px;
        padding-left: 50px;
      }
    }
  }
`;
