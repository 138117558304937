import './styles/bw-icon-font.scss';
import './styles/fonts.scss';
import './styles/index.scss';
import 'aos/dist/aos.css';

import React, { Component } from 'react';
import AOS from 'aos';
import { observer, inject } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { animateScroll as scroll } from 'react-scroll';
import * as Sentry from '@sentry/browser';

import { Provider as AlertProvider } from '@bw/library/alert';
import Layout from './App/components/Layout';
import Routes from './Routes';
import * as analytics from '@bw/services/analytics';
import theme from './styles/theme';

@inject('Store')
@withRouter
@observer
export default class App extends Component {
  componentDidMount() {
    // if (process.env.NODE_ENV !== 'production') {
    //   const { whyDidYouUpdate } = require('why-did-you-update');
    //   whyDidYouUpdate(React);
    // }

    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: 'https://da186831875145af99b65b7d846cdc03@sentry.io/300620',
        environment: process.env.RAZZLE_SERVER_TYPE,
        release: '1.2.76'
      });

      /* Load segment on the client side only */
      analytics.load();
    }

    // You can also pass an optional settings object
    // below listed default settings
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 75, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 300, // values from 0 to 3000, with step 50ms
      easing: 'ease-out', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation
    });
  }

  componentDidUpdate(prevProps) {
    const {
      Store: { App }
    } = this.props;
    if (process.env.NODE_ENV === 'production') {
      /* Call segment's page() every time the path or the query param changes on react-router */
      if (
        this.props.location.pathname !== prevProps.location.pathname ||
        this.props.location.search !== prevProps.location.search
      ) {
        analytics.page();
      }
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      scroll.scrollToTop({ smooth: false, delay: 0, duration: 0 });
      if (App.ui.mobileMenu) {
        App.ui.toggleMobileMenu(false);
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <ParallaxProvider>
          <AlertProvider>
            <Layout>
              <Routes />
            </Layout>
          </AlertProvider>
        </ParallaxProvider>
      </ThemeProvider>
    );
  }
}
