import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import cx from 'classnames';
import './alert-template.scss';

export default class AlertTemplate extends Component {
  static propTypes = {
    options: PropTypes.shape({
      type: PropTypes.oneOf(['success', 'error', 'info'])
    }),
    message: PropTypes.string,
    close: PropTypes.func
  };

  static defaultProps = {
    options: { type: 'success' }
  };

  render() {
    const { options, close, message, style } = this.props;

    const classNames = cx('a-alert', {
      [`-${options.type}`]: true
    });
    return (
      <div className={classNames} style={style}>
        {message} <Icon cursorPointer={true} icon="close" onClick={close} />
      </div>
    );
  }
}
