import { types } from 'mobx-state-tree';

const notification = types.model('notification', {
  id: types.identifier,
  link: types.optional(types.string, ''),
  label: types.optional(types.string, '')
});

export default types
  .model('AppUI', {
    alertMessage: types.maybeNull(types.string),
    alertType: types.optional(
      types.union(
        types.literal('success'),
        types.literal('error'),
        types.literal('info')
      ),
      'success'
    ),
    sequenceStatus: types.optional(types.boolean, false),
    timezone: types.optional(types.string, 'America/Los_Angeles'),
    supportTab: types.optional(types.boolean, false),
    notificationTab: types.optional(types.boolean, false),
    notifications: types.optional(types.array(notification), []),
    savingsInfo: types.optional(types.boolean, false),
    mobileMenu: types.optional(types.boolean, false),
    mobileMenuView: types.optional(types.string, ''),
    zipEntry: types.optional(types.boolean, false),
    privacyModal: types.optional(types.boolean, false),
    /* New Main Nav */
    mobileTopNav: types.optional(types.boolean, false)
  })
  .actions(self => ({
    closeZipEntry() {
      self.zipEntry = false;
    },

    openZipEntry() {
      self.zipEntry = true;
    },

    closePrivacyModal() {
      self.privacyModal = false;
    },

    openPrivacyModal() {
      self.privacyModal = true;
    },

    toggleMobileMenu() {
      self.mobileMenuView = '';
      self.mobileMenu = !self.mobileMenu;
    },

    switchMobileMenuView(menu) {
      self.mobileMenuView = self.mobileMenuView === menu ? '' : menu;
    }
  }));
