import styled from 'styled-components';

export default styled.div`
  overflow: hidden;
  position: relative;
  z-index: 0;
  height: 100%;
  height: 450px;

  @media (min-width: ${({ theme }) => theme['res-md']}) {
    height: 354px;
  }

  @media (min-width: ${({ theme }) => theme['res-lg']}) {
    height: 664px;
  }

  img {
    height: 100%;
    @include lg {
      height: auto;
    }
  }

  .gradient {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      to top,
      rgba(0, 13, 87, 0.6),
      rgba(9, 0, 47, 0)
    );
    position: absolute;
    top: 0;
  }
`;
