import React, { Fragment } from 'react';
import BWLink from './BWLink';

/**
 * This component is for the Why Solar Dropdown in navbar
 */
export default ({ directLink }) => {
  return (
    <Fragment>
      <BWLink directLink={directLink} to="/how-solar-energy-works">
        How Does Solar Energy Work?
      </BWLink>
      <BWLink directLink={directLink} to="/solar-panels-and-systems">
        Solar Panel Systems
      </BWLink>
      <BWLink directLink={directLink} to="/solar-financial-benefits">
        Financial Benefits of Solar
      </BWLink>
    </Fragment>
  );
};
