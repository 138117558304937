import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';
import uuid from 'uuid/v1';

import InputErrorField from './InputErrorField';
import Label from './Label';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import './css/input-select.scss';

/**
 * These are meant to be used with mobx-react-form
 */
@observer
export default class InputSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    displayKey: PropTypes.string,
    id: PropTypes.string,
    idKey: PropTypes.string,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    maxWidth: PropTypes.bool,
    options: PropTypes.array,
    valueKey: PropTypes.string,
    placeholder: PropTypes.string,
    showDefaultSelect: PropTypes.bool,
    tooltip: PropTypes.string,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    options: [],
    idKey: 'id',
    showDefaultSelect: true,
    valueKey: 'id',
    displayKey: 'label',
    showLabel: true
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  handleOnChange = event => {
    const { formField, onChange } = this.props;
    formField.set(event.target.value);
    if (onChange) onChange(event);
  };

  renderDefaultValue = () => {
    const { placeholder, disabled } = this.props;

    if (disabled) return '-';
    if (placeholder) return placeholder;

    return 'Select';
  };

  render() {
    const {
      align,
      className,
      disabled,
      displayKey,
      formField,
      id,
      idKey,
      inputClassName,
      isResponsive,
      label,
      maxWidth,
      options,
      readOnly,
      showDefaultSelect,
      showLabel,
      size,
      tooltip,
      valueKey
    } = this.props;

    const containerCx = cx('a-inputSelect', {
      [className]: className,
      '-error': formField.error,
      '-responsive': isResponsive,
      '-hasValue': formField.value && formField.value !== '-',
      '-disabled': disabled,
      '-maxWidth': maxWidth,
      [`-${size}`]: size,
      [`-${align}`]: align
    });

    const displayedLabel = label || formField.label;

    return (
      <div className={containerCx}>
        {showLabel && displayedLabel && (
          <Label htmlFor={id}>
            {displayedLabel}
            {tooltip && <Tooltip label={tooltip} />}
          </Label>
        )}
        <div>
          <div className="wrapper">
            <select
              {...formField.bind()}
              disabled={disabled}
              readOnly={readOnly}
              onChange={this.handleOnChange}
              id={id}
              className={cx({
                [inputClassName]: inputClassName
              })}
              ref={this.inputRef}>
              {showDefaultSelect && (
                <option key="none" value="-">
                  {this.renderDefaultValue()}
                </option>
              )}

              {options.map(option => {
                const key = _get(option, idKey, uuid());
                const optionValue =
                  valueKey === undefined ? option : option[valueKey];
                const display =
                  displayKey === undefined ? option : option[displayKey];

                return (
                  <option key={key} value={optionValue}>
                    {display}
                  </option>
                );
              })}
            </select>
            <div className="chevron">
              <Icon icon="down-arrow" />
            </div>
          </div>

          <InputErrorField text={formField.error} cy={id || formField.name} />
        </div>
      </div>
    );
  }
}
