import styled from 'styled-components';

export default styled.div`
  padding: 36px 16px;
  margin-bottom: 32px;
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme['res-lg']}) {
  }

  h1 {
    text-align: center;
  }

  .list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      max-width: 1440px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .map {
    margin: 80px auto 100px;
    text-align: center;
    .mapHolder {
      border: 1px solid ${({ theme }) => theme['color-gray3']};
      height: 500px;
      position: relative;
    }
  }
`;
