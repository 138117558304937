import axios from 'axios';
import { api } from '@bw/config';

const axiosInstance = axios.create({
  baseURL: api.root,
  timeout: api.timeout,
  headers: {
    'Content-Type': 'application/json'
  }
});

export function apiPromise(request) {
  return axiosInstance(request);
}
