import React, { Component } from 'react';

export const getStyles = ({ position, zIndex }) => {
  switch (position) {
    case 'top left':
      return {
        position: 'fixed',
        top: 0,
        right: 'auto',
        bottom: 'auto',
        left: 0,
        zIndex
      };

    case 'top right':
      return {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 'auto',
        left: 'auto',
        zIndex
      };

    case 'bottom left':
      return {
        position: 'fixed',
        top: 'auto',
        right: 'auto',
        bottom: 0,
        left: 0,
        zIndex
      };

    case 'bottom right':
      return {
        position: 'fixed',
        top: 'auto',
        right: 0,
        bottom: 0,
        left: 'auto',
        zIndex
      };

    case 'top center':
      return {
        position: 'fixed',
        top: 0,
        right: 'auto',
        bottom: 'auto',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        zIndex
      };

    case 'bottom center':
    default:
      return {
        position: 'fixed',
        top: 'auto',
        right: 'auto',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0%)',
        zIndex
      };
  }
};

class Wrapper extends Component {
  styles = getStyles(this.props.options);

  render() {
    const { children, options, ...props } = this.props;

    return (
      <div style={this.styles} {...props} className="a-alertWrapper">
        {children}
      </div>
    );
  }
}

export default Wrapper;
