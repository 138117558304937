import { getParent, types, flow } from 'mobx-state-tree';

export default types
  .model('Email', {
    modal: types.optional(types.boolean, false),
    modalType: types.optional(types.string, 'FORM')
  })
  .actions(self => ({
    toggleModal() {
      self.modal = !self.modal;
    },

    cancelRequest() {
      const parent = getParent(self);
      parent.closeAlert();
      parent.form.reset();
      parent.form.showErrors(false);
      self.modal = false;
      self.modalType = 'FORM';
    },

    /**
     * Submits the email request via Slack
     * @param {Object} user
     * @param {String} pathname - Current URL of the user
     */
    submitEmail: flow(function* submitEmail(user, pathname) {
      const parent = getParent(self);
      try {
        yield self.validateFormFieldsOnPage();

        const formValues = parent.form.values();
        // const parsedUser = JSON.stringify(user, null, 4);
        const message = `*Support Request From Platform* 
        Request: ${formValues.email.reason}
        Name: ${formValues.email.name}
        Email: ${formValues.email.address}
        Message: ${formValues.email.message}
        URL: ${pathname}
        UserRole: ${user.role}
        Company: ${user.company}
        AuthenticatedEmail: ${user.email}`;

        yield parent.postSlack(message, '#support');

        self.modalType = 'SUCCESS';
      } catch (error) {
        parent.handleError(error);
      }
    }),

    validateFormFieldsOnPage() {
      const parent = getParent(self);
      const currentFields = [
        'email.reason',
        'email.name',
        'email.address',
        'email.message'
      ];

      /* Set up each field in page to be validated */
      const fieldValidations = currentFields.map(field => {
        return parent.form.validate(field, { showErrors: true });
      });

      return Promise.all(fieldValidations).then(formFields => {
        const fieldErrors = formFields.map(field => {
          return {
            name: field.name,
            isValid: field.isValid,
            value: field.value,
            errors: field.errors()
          };
        });

        if (fieldErrors.some(field => !field.isValid)) {
          return Promise.reject(fieldErrors);
        }

        return Promise.resolve();
      });
    }
  }));
