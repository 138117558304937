import React from 'react';
import { upperCaseFirst, upperCase } from 'change-case';
import './css/input-error-field.scss';

export default ({ text, cy }) => {
  const dataCy = cy ? `ERROR_${upperCase(cy)}` : null;
  return (
    <div data-cy={dataCy} className="a-inputErrorField">
      {upperCaseFirst(text)}
    </div>
  );
};
