import React from 'react';
import './css/block-stats.scss';

export default ({ value, label, delay }) => {
  return (
    <div className="m-blockStats" data-aos="fade-up" data-aos-delay={delay}>
      <div className="m-blockStats__val">{value}</div>
      <div className="m-blockStats__desc">{label}</div>
    </div>
  );
};
