import 'mapbox-gl/dist/mapbox-gl.css';

let mapboxGl;

if (process.env.BROWSER) {
  mapboxGl = require('mapbox-gl');
}

import React, { Component } from 'react';
import { mapbox as TOKEN } from '@bw/config';
import { getProjectLocations } from '../services';

export default class CaseStudyMap extends Component {
  state = {
    map: null,
    repositioning: false,
    viewport: {
      draggable: false,
      width: '100%',
      height: '100%',
      zoom: 18,
      bearing: 0,
      pitch: 0,
      margin: '0 auto'
    },
    marker: {
      latitude: 37.785164,
      longitude: -100
    },
    events: {}
  };

  componentDidMount() {
    mapboxGl.accessToken = TOKEN;
    const map = new mapboxGl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/andrefox/cjz36cms202h61cmrmpbyi7cr',
      center: [-95.7129, 40.66995747013945],
      zoom: 3,
      maxZoom: 8,
      minZoom: 3
    });

    map.on('load', async function() {
      const response = await getProjectLocations();
      // Add a new source from our GeoJSON data and set the
      // 'cluster' option to true. GL-JS will add the point_count property to your source data.
      map.addSource('solar', {
        type: 'geojson',
        data: response.data,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'solar',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#f1f075',
            10,
            '#ffc107',
            50,
            '#ff5b08'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'solar',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'solar',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      map.addControl(new mapboxGl.NavigationControl());

      // inspect a cluster on click
      map.on('click', 'clusters', function(e) {
        var features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        var clusterId = features[0].properties.cluster_id;
        map
          .getSource('solar')
          .getClusterExpansionZoom(clusterId, function(err, zoom) {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          });
      });

      map.on('mouseenter', 'clusters', function() {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'clusters', function() {
        map.getCanvas().style.cursor = '';
      });
    });
  }

  onViewportChange = viewport => {
    const { onMapZoom } = this.props;

    this.setState({ viewport });

    if (onMapZoom) {
      onMapZoom(viewport.zoom);
    }
  };

  render() {
    const style = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%'
    };
    return <div id="map" style={style} ref={el => (this.mapContainer = el)} />;
  }
}
