import React, { Component } from 'react';
import cx from 'classnames';
import './accordion-button.scss';

export default class AccordionButton extends Component {
  render() {
    const { label, onClick, active } = this.props;
    const btnCx = cx('a-accordionButton', {
      '-active': active
    });

    return (
      <button className={btnCx} onClick={onClick}>
        {label}
      </button>
    );
  }
}
