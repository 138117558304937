import posed from 'react-pose';

export default posed.div({
  closed: {
    y: 0,
    transition: {
      duration: 500
    }
  },
  open: { y: 5 }
});
