import styled from 'styled-components';

export default styled.div`
  padding: 40px 15px 40px;
  h2 {
    margin-bottom: 32px;
  }

  @media (min-width: 992px) {
    padding: 60px 15px;
  }
`;
