import React from 'react';
import Head2 from '@bw/library/headings/Head2';

import image1 from '../images/archway/archway-image-6.jpg';
import image2 from '../images/archway/archway-image-1.jpg';
import image3 from '../images/archway/archway-image-4.jpg';
import image4 from '../images/archway/archway-image-3.jpg';
import image5 from '../images/archway/archway-image-9.jpg';

import './css/gallery.scss';
export default () => {
  return (
    <div className="archway-gallery">
      <Head2>Project Photo Gallery</Head2>
      <div className="archway-gallery__group">
        <div
          className="archway-gallery__image -one"
          data-aos="zoom-out"
          data-aos-delay="100">
          <picture>
            <img src={image1} alt="Roof solar panels" />
          </picture>
        </div>

        <div
          className="archway-gallery__image -two"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <img src={image2} alt="Roof solar panels side" />
          </picture>
        </div>

        <div
          className="archway-gallery__image -three"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <img src={image3} alt="Solar Inverters" />
          </picture>
        </div>

        <div
          className="archway-gallery__image -four"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <img src={image4} alt="Guys on solar panel roof" />
          </picture>
        </div>

        <div
          className="archway-gallery__image -five"
          data-aos="zoom-out"
          data-aos-delay="300">
          <picture>
            <img src={image5} alt="Whole solar panels" />
          </picture>
        </div>
      </div>
    </div>
  );
};
