import { types, flow, getSnapshot, applySnapshot } from 'mobx-state-tree';
import MobxReactForm from 'mobx-react-form';
import Cookies from 'universal-cookie';
import _get from 'lodash/get';
import _has from 'lodash/has';

/* Local Imports */
import { animateScroll as scroll } from 'react-scroll';
import * as services from './services';
import UI from './models/UI';
import Email from './models/Email';
import CurrentUser from './models/CurrentUser';
import fields from './fields';
import { api } from '@bw/config';

import plugins, {
  globalOptions as options
} from '@bw/modules/validationPluginConfig';

const ui = UI.create();
const email = Email.create();
const currentUser = CurrentUser.create();

const AppStore = types
  .model('AppStore', {
    ui: types.optional(UI, getSnapshot(ui)),
    email: types.optional(Email, getSnapshot(email)),
    currentUser: types.optional(CurrentUser, getSnapshot(currentUser))
  })

  .actions(self => ({
    afterCreate() {
      const cookie = new Cookies();
      const currentUser = cookie.get('currentUser');

      if (currentUser) {
        applySnapshot(self.currentUser, currentUser);
      }

      /* Actual initialization of the mobx-react-form */
      self.form = new MobxReactForm({ fields }, { options, plugins });
    },

    /**
     * Post slack updates to specific channel
     * @param {String} message
     * @param {String} channel
     */
    postSlack: flow(function* postSlack(message, channel) {
      try {
        console.log({ message, channel });
        yield services.postSlack(message, channel);
      } catch (error) {
        self.handleError(error);
      }
    }),

    /**
     * Initialize the Alert component to work with the methods passed in from the component.
     * @param {Object} alert - The alert component itself with the methods to use for displaying the component on the UI
     */

    initAlert(alert) {
      self.alert = alert;
    },

    /**
     * Handle errors coming in from the different Stores and it's API calls
     * @param {Object} error
     */
    handleError(error) {
      self.ui.state = 'error';

      let alert = _get(error, 'response');
      const hasAlertMessage = _has(alert, 'message');

      if (hasAlertMessage) {
        alert = alert.message;
      } else if (alert === undefined || typeof alert === 'object') {
        alert =
          'Oops! Something unexpected happened... Please try again later.';
      }

      self.setAlert({
        type: 'error',
        message: alert
      });
    },

    /**
     * Pop up the App alert component.
     * @param {Object} alert
     * @param {String} alert.message
     * @param {String} alert.type - success, info, error
     */
    setAlert({ message, type }) {
      self.scrollTop();
      self.alert.show(message, { type });
    },

    scrollTop(duration = 200) {
      scroll.scrollToTop({ duration });
    },

    /**
     * Closes the App alert component
     */
    closeAlert() {
      self.ui.alertMessage = 'success';
      self.ui.alertMessage = null;
    },

    resetView() {
      self.closeAlert();
    },

    redirectUser() {
      let url = `${api.appURL}/projects`;
      switch (self.currentUser.role) {
        case 'credit':
          url = `${api.appURL}/credits/credit-application`;
          break;

        case 'enterprise':
          url = `${api.appURL}/enterprises`;
          break;

        default:
          url = `${api.appURL}/projects`;
          break;
      }

      window.location = url;
    }
  }));

export const AppStoreCreate = AppStore.create();
export default AppStore;
