import styled from 'styled-components';

export default styled.div`
  background: ${({ theme }) => theme['color-gray1']};
  padding: 0 15px;

  &.-ronald {
    grid-area: quoteRonald;
    margin-bottom: 90px;

    @media (min-width: ${({ theme }) => theme['res-md']}) {
      margin-bottom: 0;
      padding-right: 22px;
    }
  }

  &.-gary {
    grid-area: quoteGary;
    position: relative;
    background: none;
  }
`;
