import React, { PureComponent } from 'react';
import cx from 'classnames';

export default class Head1 extends PureComponent {
  render() {
    const { children, align, className, line, ...rest } = this.props;
    const headCx = cx({
      [`-${align}`]: align,
      '-line': line,
      [className]: className
    });
    return (
      <h1 className={headCx} {...rest}>
        {children}
      </h1>
    );
  }
}
