import styled from 'styled-components';

export default styled.div`
  margin: 20px 0;
  height: auto;
  border: 1px solid ${({ theme }) => theme['color-gray1']};
  box-shadow: 0px 3px 6px 0px #f8f8f8;
  cursor: ${props => (props.item.comingSoon ? 'initial' : 'cursor')};
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme['res-lg']}) {
    width: 48%;
    min-width: 300px;
  }

  &:hover {
    color: ${props =>
      props.item.comingSoon ? props.theme['color-gray5'] : 'inherit'};
  }

  &:first-child {
    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      margin-left: 0;
    }
  }

  &:last-child {
    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      margin-right: 0;
    }
  }

  a {
    text-decoration: none;
  }

  .image {
    background-image: ${props => `url(${props.item.thumb})`};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    width: 100%;
    cursor: pointer;

    @media (min-width: ${({ theme }) => theme['res-lg']}) {
      height: 300px;
    }
  }

  .bottom {
    padding: 8px 20px 12px;
  }

  .title {
    font-size: 22px;
  }

  .location {
    font-size: 14px;
    text-decoration: none !important;
  }
`;
