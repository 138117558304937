import moment from 'moment';

export default {
  validTelephone: {
    customFunction: value => {
      return value.match(/^\d{3}-\d{3}-\d{4}$/);
    },
    message: 'The :attribute phone number is not in the format XXX-XXX-XXXX.'
  },

  requiredSelect: {
    customFunction: value => {
      return value !== '-';
    },
    message: ':attribute is required'
  },

  zipcode: {
    customFunction: value => {
      const zipRegex = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/);
      return zipRegex.test(value);
    },
    message: 'Please enter a valid US zipcode'
  },

  customMax: {
    customFunction: (value, maxVal) => {
      return Number(value) <= Number(maxVal);
    },
    message: ':attribute should be equal or less than :customMax'
  },

  customMin: {
    customFunction: (value, minVal) => {
      return Number(value) >= Number(minVal);
    },
    message: ':attribute should be greater than :customMin'
  },

  isValidDate: {
    customFunction: value => {
      const today = moment(value, 'MM/DD/YYYY');
      return today._isValid;
    },
    message: 'Please enter a valid date'
  },

  isValidYear: {
    customFunction: value => {
      const today = moment();
      const currentValue = moment(value, 'YYYY');
      return currentValue.isSameOrBefore(today);
    },
    message: 'Please enter a valid year in the past'
  },

  isBeforeToday: {
    customFunction: value => {
      const today = moment();
      const currentValue = moment(value, 'MM/DD/YYYY');
      return currentValue.isBefore(today);
    },
    message: ":attribute should be before today's date"
  },

  isAcLessThanDc: {
    customFunction(val, req, attribute) {
      const index = attribute.replace(/\D/g, '');
      const reqValue = this.validator.input[`orientations.${index}.${req}`];
      return Number(reqValue) > Number(val);
    },
    message: 'AC system size must be less than the DC system size'
  },

  isDcGreaterThanAc: {
    customFunction(val, req, attribute) {
      const index = attribute.replace(/\D/g, '');
      const reqValue = this.validator.input[`orientations.${index}.${req}`];
      return Number(reqValue) < Number(val);
    },
    message: 'DC system size must be greater than the AC system size'
  },

  isAcDcRatioValid: {
    customFunction(val, req, attribute) {
      const index = attribute.replace(/\D/g, '');
      const reqValue = this.validator.input[`orientations.${index}.${req}`];
      if (Number.isNaN(reqValue) || Number.isNaN(val)) return false;
      if (!reqValue) return false;
      return val >= reqValue / 1.5;
    },
    message: 'AC size must be equal or greater than 66% of DC system size'
  }
};
