import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import Button from '@bw/library/Button';
import CallToAction from '@bw/library/CallToAction';
import './simple-footer.scss';

@inject('Store')
@observer
export default class SimpleFooter extends Component {
  render() {
    const { Store, hideMobile, background } = this.props;
    var version = '1.2.76';
    const divCx = cx('a-simpleFooter', {
      '-hideMobile': hideMobile,
      '-background': background
    });

    return (
      <div className={divCx}>
        <div className="a-simpleFooter__desktop">
          <div className="a-simpleFooter__question">
            Or talk with one of our reps
          </div>
          <div className="a-simpleFooter__flex">
            <CallToAction location="footer" size="md" />
          </div>
          <Button
            className="a-simpleFooter__privacy"
            type="simpleWithLine"
            label="Secured Privacy"
            onClick={Store.App.ui.openPrivacyModal}
          />
        </div>
        <div className="a-simpleFooter__version">
          Site V1.2.76
        </div>
      </div>
    );
  }
}
