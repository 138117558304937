import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import './css/column.scss';

class Column extends Component {
  static propTypes = {
    align: PropTypes.oneOf(['center', 'flex-end']),
    className: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.object,
    colSize: PropTypes.number,
    gridSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    sizeSm: PropTypes.number,
    sizeMd: PropTypes.number,
    sizeLg: PropTypes.number,
    sizeXl: PropTypes.number,
    sizeSmOffSet: PropTypes.number,
    sizeMdOffSet: PropTypes.number,
    sizeLgOffSet: PropTypes.number,
    sizeXlOffSet: PropTypes.number
  };

  static defaultProps = {
    colSize: 12
  };

  render() {
    const {
      align,
      gridSize,
      colSize,
      style,
      className,
      children,
      sizeSm,
      sizeMd,
      sizeLg,
      sizeXl,
      sizeSmOffSet,
      sizeMdOffSet,
      sizeLgOffSet,
      sizeXlOffSet
    } = this.props;

    let colSizing;

    if (gridSize && colSize) {
      colSizing = `col-${gridSize}-${colSize}`;
    } else if (gridSize) {
      colSizing = `col-${gridSize}`;
    } else if (colSize) {
      colSizing = `col-${colSize}`;
    } else {
      colSizing = 'col';
    }

    return (
      <div
        style={style}
        className={cx('a-column', {
          [className]: className,
          [colSizing]: colSizing,
          [`-${align}`]: align,
          [`col-sm-${sizeSm}`]: sizeSm,
          [`col-md-${sizeMd}`]: sizeMd,
          [`col-lg-${sizeLg}`]: sizeLg,
          [`col-xl-${sizeXl}`]: sizeXl,
          [`offset-sm-${sizeSmOffSet}`]: sizeSmOffSet,
          [`offset-md-${sizeMdOffSet}`]: sizeMdOffSet,
          [`offset-lg-${sizeLgOffSet}`]: sizeLgOffSet,
          [`offset-xl-${sizeXlOffSet}`]: sizeXlOffSet
        })}>
        {children}
      </div>
    );
  }
}

export default Column;
